import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./sidebar.scss";
import SideBarNavigator, { Navigator } from "./utils/sidebar-navigator";
import UserDataStorage from "../../../singletonStorage/userDataStorage";
import { useWindowSize } from "../../../utility/customHooks";

function Sidebar() {
  const userData: UserDataStorage = UserDataStorage.getInstance();

  const [width] = useWindowSize();

  const navigator: SideBarNavigator = new SideBarNavigator();

  const [buttons, setButtons] = useState<Array<JSX.Element>>([]);

  useEffect(() => {
    setButtons(
      navigator.getNavigation
        .filter((e) => {
          if (!userData.sessionStorage?.roles && e.roleGuard) {
            return;
          }
          if (userData.sessionStorage?.roles && e.roleGuard) {
            const contains = userData.sessionStorage?.roles.some(
              (_e: string) => {
                return e.roleGuard!.includes(_e);
              }
            );
            if (contains) {
              return e;
            } else {
              return;
            }
          }
          return e;
        })
        .map((e: Navigator) => Icon(e))
    );
  }, [userData]);

  return (
    <div className="sidebar">
      {width > 768 && (
        <div className="logoContainer">
          <div className="logo"></div>
          <span className="text-center">
            StepUp {process.env.REACT_APP_ENV !== "prod" && "DEV"}
          </span>
        </div>
      )}
      <div className="sidebar-content">
        <div
          className={
            "d-flex " + (width <= 768 ? "sidebar-row row" : "flex-column mt-3")
          }
        >
          {buttons}
          <div
            className={"sidebarButton"}
            key={"discord"}
            onClick={() => {
              window.open("https://discord.gg/nGHvs2NBcc", "_blank");
            }}
          >
            <div className="position-relative">
              <i className={"fa-brands fa-discord"} />
            </div>
          </div>
          <div
            className={"sidebarButton"}
            key={"helper"}
            onClick={() => {
              window.open("https://stepup-1.gitbook.io/stepup/", "_blank");
            }}
          >
            <div className="position-relative">
              <i className={"fa-solid fa-circle-info"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Icon(navigator: Navigator) {
  return (
    <NavLink
      className={({ isActive }) => {
        return (
          "sidebarButton " +
          (isActive ? "selected" : "") +
          (navigator.disabled ? "disabled" : "")
        );
      }}
      to={navigator.disabled ? window.location.href : navigator.link}
      key={navigator.name}
      onClick={(e) => {
        navigator.disabled && e.preventDefault();
      }}
    >
      <div className="position-relative">
        {navigator.disabled && (
          <i className="fa-solid fa-slash disabledSidebarOption"></i>
        )}
        <i
          className={
            navigator.fa_icon + (navigator.disabled ? " disabled" : "")
          }
        />
      </div>
    </NavLink>
  );
}

export default Sidebar;
