import React, { useEffect, useState } from 'react';
import './SaleSection.scss';
import { InventoryResultInterface, Sale } from "../../../../../services/InventoryInterfaces";
import Input from "../../../../common/input/Input";
import { formatPrice, getDateByTimeStamp } from "../../../../../utility/commonUtils";
import Select from '../../../../common/select/Select';
import UserDataStorage from '../../../../../singletonStorage/userDataStorage';
import Switch from '../../../../common/switch/Switch';
import { schemaErrorsViewOrEditModal } from "../../ViewOrEditItemModal";
const _ = require('lodash');

interface SaleSectionProps {
    item?: InventoryResultInterface,
    locked: boolean,
    onChange: (args: Record<any, any>) => void;
    error?: typeof schemaErrorsViewOrEditModal["sale"];
}

function getCurrencySymbol(currency: string) {
    switch (currency) {
        case "AED":
            return "د.إ";
        case "AUD":
            return "A$";
        case "EUR":
            return "€";
        case "TWD":
            return "NT$";
        case "CHF":
            return "CHF";
        case "CNY":
            return "¥";
        case "GBP":
            return "£";
        case "HKD":
            return "HK$";
        case "HRK":
            return "kn";
        case "NZD":
            return "NZ$";
        case "PLN":
            return "zł";
        case "USD":
            return "$";
        case "CAD":
            return "C$";
        case "CNH":
            return "CNH";
        case "JPY":
            return "¥";
        case "RUB":
            return "₽";
        case "SGD":
            return "S$";
        case "TRY":
            return "₺";
        default:
            return "";
    }
}

const SaleSection: React.FC<SaleSectionProps> = (props) => {

    const [item, setItem] = useState<Sale | undefined>(props.item?.sale);

    const userData = UserDataStorage.getInstance();

    useEffect(() => {
    }, [props, userData.settingsStorage]);

    const updateItem = (fieldToUpdate: any) => {
        setItem((item: any) => {
            if (item) {
                return { ..._.merge(item, fieldToUpdate) };
            } else {
                return { ..._.merge(fieldToUpdate) };
            }
        })
        if (item) {
            props.onChange({
                sale:
                    { ...fieldToUpdate }
            })
        }

    }

    return (
        <>
            <div>
                {props.item && <>
                    <div className={"mt-3 pt-3"}>
                        <div className={"row justify-content-between mt-3"}>
                            <div className={"col-6 col-sm-3"}>
                                <label className={"labelModal"} htmlFor={"saleDate"}>Sale Date</label>
                                <Input defaultValue={item?.saleDate ? getDateByTimeStamp(item?.saleDate, true) : ""} error={props.error?.saleDate} type={"date"} customClass={"smallPlaceholder"} id={"saleDate"} placeholder={"DD-MM-YYYY"} onChange={(str) => updateItem({ saleDate: str })} disabled={props.locked} />
                            </div>
                            <div className={"col-6 col-sm-3"}>
                                <label className={"labelModal"} htmlFor={"saleStore"}>Sale Store</label>
                                <Select selectedValue={item?.store} value={userData.sessionStorage!['marketplaces']} customClass={"smallPlaceholder w-100"} id={"saleStore"} onChange={(str) => updateItem({ store: str })} disabled={props.locked} />
                            </div>
                            <div className={"col-12 col-sm-3"}>
                                <label className={"labelModal"} htmlFor={"salePrice"}>Sale Price</label>
                                <Input id={"purPrice"}
                                    type={"price"}
                                    onChangeSelect={(str) =>
                                        updateItem({
                                            ...{
                                                price: {
                                                    currency: str,
                                                    value: item?.price.value || userData.sessionStorage!['currencies']
                                                }
                                            }
                                        })}
                                    valueSelect={userData.sessionStorage!['currencies']}
                                    defaultValue={formatPrice(item?.price?.value)}
                                    defaultValueSelect={item?.price?.currency || userData.settingsStorage!['currency']}
                                    onChange={(str) => updateItem({
                                        price: {
                                            currency: item?.price?.currency || userData.settingsStorage!['currency'],
                                            value: Number(Number(str?.replaceAll(',', '.')).toFixed(2))
                                        }
                                    })}
                                    disabled={props.locked}
                                />
                            </div>
                        </div>
                        <div className={"row justify-content-between mt-2"}>
                            <div className={"col-6 col-sm-3"}>
                                <label className={"labelModal"} htmlFor={"saleID"}>Sale ID</label>
                                <Input defaultValue={item?.saleId} customClass={"smallPlaceholder"} id={"saleID"} onChange={(str) => updateItem({ saleId: str })} disabled={props.locked} />
                            </div>
                            <div className={"col-4 col-sm-3"}>
                                <label className={"labelModal"} htmlFor={"profit"}>Profit</label>
                                <Input defaultValue={(formatPrice(item?.profit?.value) || "") + " " + (getCurrencySymbol(item?.profit?.currency || "") || "")} customClass={`${formatPrice(item?.profit?.value)?.includes("-") ? 'itemRed' : 'itemGreen'} smallPlaceholder`} id={"profitID"} onChange={() => { }} disabled={true} />
                            </div>
                            <div className={"col-2 col-sm-3"}>
                                <label className={"labelModal"} htmlFor={"paid"}>Paid</label>
                                <Switch id={"paid"} value={item?.paied} customClass={"mt-2"} onChange={(bool) => { updateItem({ paied: bool }) }} disabled={props.locked} />
                            </div>
                        </div>
                        <div className={"mt-2 pt-3"}>
                            <div>
                                <label htmlFor="notes" className="labelModal mb-0">Notes</label>
                            </div>
                            <textarea className="text-area-settings w-100" id="notes" value={item?.saleNotes || ""} onChange={(event) => updateItem({ saleNotes: event.target.value })} disabled={props.locked}></textarea>
                        </div>
                    </div>
                </>
                }
                {!props.item && <>
                    <div className={"mt-4 pt-4 mb-4 pb-4 color-white text-center"}>
                        <div className={"mt-4 pt-4 mb-2 pb-2"}><i className="fa-solid fa-face-dizzy h1"></i></div>
                        <div>Uh Oh! No Sale Found for this item</div>
                    </div>
                </>
                }
            </div>
        </>
    );
}


export default SaleSection;