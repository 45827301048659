import httpClient from "../../utility/httpClient"
import {InventoryListInterface} from "../InventoryInterfaces";
import {FiltersInterface} from "../../utility/filtersUtils";
import { InventoryFilters as InventoryFiltersInterface } from "./InventoryFilterInterfaces";
import { PaginationFiltersInterface } from "../CommonInterfaces";

export default class InventoryListService {

    MAX_ELEMENTS: number = 15;

    searchUrl = "/api/v1/inventory/";

    public async getProducts(sortFilters?: FiltersInterface[], filters?: InventoryFiltersInterface, paginationFilter?: PaginationFiltersInterface): Promise<InventoryListInterface> {

        let orderBy = (sortFilters?.find(e=>e.lastSelect)!.value ? "+": "-") + (sortFilters?.find(e=>e.lastSelect)!.name);

        let id = 'USERIDAUTH0'; //:invIdId
        let path = "/filterPurchases";
        let page = "?page="+ (paginationFilter?.page ? paginationFilter?.page + 1 : "1") + "&";
        let sizePage = "itemperpage=" + this.MAX_ELEMENTS;
        let body = {
            "Name": filters?.searchFilters || "",
            "PurchaseDate": filters?.purDateFilter || [],
            "ShippingDate": [],
            "SaleDate": [],
            "Slug": "inventory",
            "Sizes": filters?.sizeFilter || [],
            "PurchaseStore": filters?.purStoreFilter || [],
            "ListedOn": [],
            "OrderBy": orderBy,
            "SkipDuplicates": filters?.hideDuplicates,
            "With": "purchase.item,purchase.variant,variant.size,purchase.listings,purchase.sale"
        };
        let url = this.searchUrl + id + path + page + sizePage;
        return httpClient.post(url, body).then((e: any)=> {
            return e.data
        });
    }

    public async addItemMultisize(obj: any): Promise<any> {
        let id = 'USERIDAUTH0'; //:invId
        let path = "/addPurchases";
        let url = this.searchUrl + id + path;
        return httpClient.post(url, obj).then((e: any)=>e.data);
    }

    public async deletePurchase(itemsIds: Array<string>): Promise<any> {
        let id = 'USERIDAUTH0'; //:invIdId
        let url = this.searchUrl + id;
        let body = {
            'itemsIds': itemsIds
        }
        return httpClient.delete(url, {data: body}).then((e: any)=>e.data);
    }

    public async updatePurchase(itemId: string, item: Record<any, any>): Promise<any> {
        let id = 'USERIDAUTH0'; //:invIdId
        let itemPath = ('/' + itemId);
        let url = this.searchUrl + id + itemPath;
        return httpClient.patch(url, item).then((e: any)=>e.data);
    }

    public async export(sortFilters?: FiltersInterface[], filters?: InventoryFiltersInterface, paginationFilter?: PaginationFiltersInterface) {
        let orderBy = (sortFilters?.find(e=>e.lastSelect)!.value ? "+": "-") + (sortFilters?.find(e=>e.lastSelect)!.name);

        let id = 'USERIDAUTH0'; //:invIdId
        let path = "/exportInventory";
        let page = "?page="+ (paginationFilter?.page ? paginationFilter?.page + 1 : "1") + "&";
        let sizePage = "itemperpage=" + this.MAX_ELEMENTS;
        let body = {
            "Name": filters?.searchFilters || "",
            "PurchaseDate": filters?.purDateFilter || [],
            "ShippingDate": [],
            "SaleDate": [],
            "Slug": "inventory",
            "Sizes": filters?.sizeFilter || [],
            "PurchaseStore": filters?.purStoreFilter || [],
            "ListedOn": [],
            "OrderBy": orderBy,
            "SkipDuplicates": filters?.hideDuplicates,
            "With": "purchase.item,purchase.variant,variant.size,purchase.listings,purchase.sale"
        };
        let url = this.searchUrl + id + path + page + sizePage;
        return httpClient.post(url, body).then((e: any)=> {
            return e.data
        });
    }

}