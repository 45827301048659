import React, { useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from '../../common/header/Header';
import './Content.scss';
import Dashboard from './dashboard/Dashboard';
import Settings from "./Settings/Settings";
import Inventory from "./Inventory/Inventory";
import Wtbs from "./Wtbs/Wtbs";
import Listed from "./Listed/Listed";
import Sold from "./Sold/Sold";
import IncomesExpenses from "./ExpenseIncome/IncomesExpenses";
import Shippings from "./Shippings/Shippings";
import { AnimatePresence } from 'framer-motion';
import { motion } from "framer-motion";
import AdminInventory from "./AdminInventory/AdminInventory";
import Sell from './Sell/Sell';

function Content() {

  const location = useLocation();
  const contentRef = useRef(null);
  const durationAnimationMs = 0.15;

  useEffect(() => {
    if (contentRef && contentRef.current) {
      switch (location.pathname) {
        case '/dashboard':
        case '/settings':
          (contentRef.current as any).style.overflowY = 'auto';
          break;
        default:
          (contentRef.current as any).style.overflowY = 'hidden';
          break;
      }
    }
  }, [location])

  const DashboardMotion = React.forwardRef((props, ref: any) => (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: durationAnimationMs }}
    >
      <Dashboard />
    </motion.div>
  ));

  const InventoryMotion = React.forwardRef((props, ref: any) => (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: durationAnimationMs }}
    >
      <Inventory />
    </motion.div>
  ));

  const WtbsMotion = React.forwardRef((props, ref: any) => (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: durationAnimationMs }}
    >
      <Wtbs />
    </motion.div>
  ));

  const SellMotion = React.forwardRef((props, ref: any) => (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: durationAnimationMs }}
    >
      <Sell />
    </motion.div>
  ));

  const ListedMotion = React.forwardRef((props, ref: any) => (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: durationAnimationMs }}
    >
      <Listed />
    </motion.div>
  ));

  const SoldMotion = React.forwardRef((props, ref: any) => (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: durationAnimationMs }}
    >
      <Sold />
    </motion.div>
  ));

  const IncomeExpenseMotion = React.forwardRef((props, ref: any) => (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: durationAnimationMs }}
    >
      <IncomesExpenses />
    </motion.div>
  ));

  const ShippingsMotion = React.forwardRef((props, ref: any) => (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: durationAnimationMs }}
    >
      <Shippings />
    </motion.div>
  ));

  const SettingsMotion = React.forwardRef((props, ref: any) => (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: durationAnimationMs }}
    >
      <Settings />
    </motion.div>
  ));

  const DashboardComponent = motion(DashboardMotion);
  const InventoryComponent = motion(InventoryMotion);
  const WtbsComponent = motion(WtbsMotion);
  const SellComponent = motion(SellMotion);
  const ListedComponent = motion(ListedMotion);
  const SoldComponent = motion(SoldMotion);
  const IncomesExpensesComponent = motion(IncomeExpenseMotion);
  const ShippingsComponent = motion(ShippingsMotion);
  const SettingsComponent = motion(SettingsMotion);

  return (
    <div className={'contentWrap'}>
      <AnimatePresence mode='wait'>
        <Routes location={location} key={location.key}>
        <Route path="/settings" element={(<Header show={{notification: true}} />)}></Route>
          <Route path="*" element={(<Header />)}></Route>
        </Routes>
      </AnimatePresence>
      <div className='content' ref={contentRef}>
        <AnimatePresence mode='wait'>
          <Routes location={location} key={location.pathname}>
            <Route path="/dashboard" element={<DashboardComponent />}></Route>
            <Route path="/inventory" element={<InventoryComponent />}></Route>
            <Route path="/wtbs" element={<WtbsComponent />}></Route>
            <Route path="/sell" element={<SellComponent />}></Route>
            <Route path="/admin_Inventory" element={<AdminInventory />}></Route>
            <Route path="/listed" element={<ListedComponent />}></Route>
            <Route path="/sold" element={<SoldComponent />}></Route>
            <Route path="/incomes-expenses" element={<IncomesExpensesComponent />}></Route>
            <Route path="/shipping" element={<ShippingsComponent />}></Route>
            <Route path="/settings" element={<SettingsComponent />}></Route>
            <Route path="*" element={
              (<div className='d-flex w-100 flex-column justify-content-center align-items-center'>
                <span className='h2 color-white'>Welcome in StepUp!</span>
                <span className='color-white text-center listMessage'>To get started read our guide or join our Discord Server to get assistance</span>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                  <div
                    className={"sidebarButton m-1"}
                    key={"discord"}
                    onClick={() => {
                      window.open("https://discord.gg/nGHvs2NBcc", "_blank");
                    }}
                  >
                    <div className="position-relative">
                      <i className={"fa-brands fa-discord"} />
                    </div>
                  </div>
                  <div
                    className={"sidebarButton m-1"}
                    key={"helper"}
                    onClick={() => {
                      window.open("https://stepup-1.gitbook.io/stepup/", "_blank");
                    }}
                  >
                    <div className="position-relative">
                      <i className={"fa-solid fa-circle-info"} />
                    </div>
                  </div>
                </div>
              </div>)
            }></Route>
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Content;
