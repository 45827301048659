import { jwtDecode } from "jwt-decode";
import ToastHandler from "../utility/ToastHandler/ToastHandler";
import httpClient, { addAccessTokenInterceptor } from "../utility/httpClient";

export interface User {
    id:       string;
    userName: string;
    email:    string;
    country:  string;
    roles:    any[];
    reviews:  any;
    settings: Settings;
}

export interface Settings {
    discordWebhook:       string;
    currency:             string;
    sizeCountry:          string;
    customPurchaseStores: Array<string>;
    customSaleStores:     Array<string>;
    customService:        Array<string>;
}


export default class UserDataStorage {

    private userDataUrl = {
        accessToken: "/oauth/v1/login",
        refreshRoles: "/oauth/v1/USERIDAUTH0/refreshroles",
        getUserUrl: "/api/v1/users/USERIDAUTH0?with=user.settings,user.reviews",
        getSession: "/api/v1/users/USERIDAUTH0/session",
        updateSetting: "/api/v1/users/USERIDAUTH0/settings",
        firebaseToken: "/api/v1/users/USERIDAUTH0/firebaseToken",
        importFile: "/api/v1/inventory/USERIDAUTH0/actions/import",
        exportFile: "/api/v1/inventory/USERIDAUTH0/actions/export",
        importFileIncExp: "/api/v1/incomes-expenses/USERIDAUTH0/actions/import",
        exportFileIncExp: "/api/v1/incomes-expenses/USERIDAUTH0/actions/export",
    }

    private static instance: UserDataStorage;
    private constructor() { }

    userStorage: User | undefined;
    settingsStorage: Record<any, any> | undefined;
    sessionStorage: Record<any, any> | undefined;
    sessionFireBaseToken: string | undefined;
    public static getInstance(): UserDataStorage {
        if (!UserDataStorage.instance) {
            UserDataStorage.instance = new UserDataStorage();
        }

        return UserDataStorage.instance;
    }

    public async getUser(): Promise<any> {
        let url = this.userDataUrl.getUserUrl;
        return httpClient.get(url).then(e=> {
            this.userStorage = e.data;
            this.settingsStorage = e.data.settings;
            return e.data;
        });
    }

    public async getUserSession(): Promise<any> {
        let url = this.userDataUrl.getSession;
        return httpClient.get(url).then(e=> {
            const jwt: any = jwtDecode(localStorage.getItem("UserJWT")!)
            if (jwt.roles) {
                e.data.roles = jwt.roles;
            }
            this.sessionStorage = e.data;
            return e.data;
        });
    }

    
    public async refreshRoles(): Promise<any> {
        let url = this.userDataUrl.refreshRoles;
        return httpClient.get(url).then(e=> {
            if (e?.data?.jwt) {
                localStorage.setItem('UserJWT', e?.data?.jwt);
                addAccessTokenInterceptor();
            } else {
                ToastHandler.customToast({message: "Something occurred, please retry or contact assistance"});
            }
            return e.data;
        }).catch(()=>{
            ToastHandler.customToast({message: "Something occurred, please retry or contact assistance"});
        });
    }

    public async getAccessToken(code?: string): Promise<any> {
        let url = this.userDataUrl.accessToken;
        const origin = window.location.origin + '/oauth/login';
        const body = {
            "code": code,
            "redirect": origin
        }
        return httpClient.post(url, body).then(e=> {
            if (e?.data?.jwt) {
                localStorage.setItem('UserJWT', e?.data?.jwt);
                addAccessTokenInterceptor();
            } else {
                ToastHandler.customToast({message: "Error during login, please retry or contact assistance"});
            }
            return e.data;
        }).catch(()=>{
            ToastHandler.customToast({message: "Error during login, please retry or contact assistance"});
        });
    }

    public async updateSettings(setting: Object): Promise<any> {
        let url = this.userDataUrl.updateSetting;
        return httpClient.post(url, setting).then(e=> {
            this.settingsStorage = setting;
            return e.data;
        });
    }

    public async importFile(file: FormData): Promise<any> {
        let url = this.userDataUrl.importFile;
        return httpClient.post(url, file).then(e=> {
            return e.data;
        });
    }

    public async exportFile(): Promise<any> {
        let url = this.userDataUrl.exportFile;
        return httpClient.get(url, {responseType: 'blob'}).then(e=> {
            const href = URL.createObjectURL(e.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'StepUpFile.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            return e.data;
        });
    }

    public async importFileIncomesExpenses(file: FormData): Promise<any> {
        let url = this.userDataUrl.importFileIncExp;
        return httpClient.post(url, file).then(e=> {
            return e.data;
        });
    }

    public async exportFileIncomesExpenses(): Promise<any> {
        let url = this.userDataUrl.exportFileIncExp;
        return httpClient.get(url, {responseType: 'blob'}).then(e=> {
            const href = URL.createObjectURL(e.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'Income-Expenses.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            return e.data;
        });
    }

    public async firebaseTokenSubscription(firebaseToken: string): Promise<any> {
        let url = this.userDataUrl.firebaseToken;
        return httpClient.post(url, {firebaseToken: firebaseToken}).then(e=> {
            return e.data;
        });
    }
}