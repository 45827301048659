import React, { useEffect, useState } from 'react';
import Content from '../main/content/Content';
import Sidebar from '../main/sidebar/Sidebar';
import './Home.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import SideBarNavigator from '../main/sidebar/utils/sidebar-navigator';

function Home() {

  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();

  const navigator: SideBarNavigator = new SideBarNavigator();

  useEffect(() => {
    const pathnames = navigator.getNavigation.map(e=>e.link);
    if (!pathnames.includes(location.pathname)) {
      navigate('/');
    }    
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setWidth(window.innerWidth);
      }, 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={'w-100 h-100 d-flex ' + (width <= 768 ? 'flex-column-reverse' : "")}>
      {/*SIDEBAR*/ <Sidebar />}
      {/*LARGE SECTION - NEXT TO SIDEBAR*/ <Content />}
    </div>
  );
}

export default Home;
