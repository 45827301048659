import React, { useEffect, useState } from "react"
import './rowFilters.scss';
import FiltersUtils, { FiltersInterface } from "../../../utility/filtersUtils";
import Checkbox from "../checkbox/Checkbox";
import { useWindowSize } from "../../../utility/customHooks";
import Select from "../select/Select";
import MultiSelect from "../multiselect/MultiSelect";
import MultiSelectFilters from "../multiselectfilters/MultiSelectFilters";


interface RowFiltersProps {
    type?: string
    filters?: Array<FiltersInterface>,
    handleCheckbox?: (v: boolean) => void,
    handleFilterClick: (name?: string) => void,
    customClass?: string,
    selected?: boolean,
    hideInfo?: boolean
}

const RowFilters: React.FC<RowFiltersProps> = (props) => {

    const [selectedAll, setSelectedAll] = useState<boolean>(false);

    const [width] = useWindowSize();

    useEffect(() => {
        props.selected && setSelectedAll(props.selected)
    }, [props.selected]);

    return (
        <>
            <div className="rowFilters">
                {props.handleCheckbox && props.type !== 'adminInventory' && <>
                    <div>
                        <Checkbox customClass="me-2" selected={selectedAll} id="inventoryCheckbox" onClick={() => {
                            setSelectedAll(!selectedAll)
                            props.handleCheckbox!(!selectedAll)
                        }} />
                    </div>
                </>
                }
                <span style={{ width: 56 }} />
                <div className="w-100 ms-2 pl-2">
                    <div className="row gx-0 d-flex align-items-center justify-content-between">
                        {(width > 768) && props.filters?.map((v) => {
                            return <div key={v.name} className={"col-" + (props.customClass ? 'auto' : v.col) + " p-0 d-flex justify-content-start"}>
                                <div className={"d-flex cursor-pointer"} onClick={() => props.handleFilterClick(v.name)}>
                                    <span className={"align-self-center" + (v.lastSelect ? " text-decoration-underline" : "")} style={{ width: "max-content", fontWeight: (v.lastSelect ? 800 : 400) }}>{v.label.toUpperCase()}</span>
                                    {!v.noArr && <i className={"ps-1 align-self-center fa-solid fa-caret-" + (v.value ? "up" : "down")}></i>}
                                </div>
                            </div>;
                        })}
                        {(width <= 768) && <div className="d-flex justify-content-end me-1 pe-5">
                            <MultiSelectFilters id="orderFilter" value={props.filters?.map(v => ({ label: v.label.toUpperCase(), name: v.name, lastSelect: v.lastSelect, value: v.value, noArr: v.noArr }))} customClass='selectFilters ms-3' placeholder="Order By" onChange={(str) => { props.handleFilterClick(str) }} />
                        </div>}
                        {<div className={"optionsFilters"}>
                            {<div className={"btn btn-transparent text-white"} onClick={() => {
                                FiltersUtils.resetFilters();
                                props.handleFilterClick();
                            }}>
                                <i className="fa-solid fa-broom h6 mb-0 pt-1"></i>
                            </div>}
                            {props.hideInfo !== undefined &&
                                <div className={"btn btn-transparent text-white"} onClick={() => {
                                    props.handleFilterClick('hideInfo')
                                }}>
                                    <i className={`fa-solid fa-eye${props.hideInfo ? '-slash' : ''} h6 mb-0 pt-1`}></i>
                                </div>
                            }
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
}


export default RowFilters;