export interface Navigator {
    name: string;
    link: string;
    fa_icon: string;
    disabled?: boolean;
    hide?: boolean;
    roleGuard?: string[]
}

export default class SideBarNavigator {

    navigation: Array<Navigator> = [
        {
            name: 'Dashboard',
            link: '/dashboard',
            fa_icon: 'fa-solid fa-chart-pie'
        },
        {
            name: 'Inventory',
            link: '/inventory',
            fa_icon: 'fa-solid fa-clipboard-list'
        },
        {
            name: 'Admin Inventory',
            link: '/admin_Inventory',
            fa_icon: 'fa-solid fa-users',
            roleGuard: ["!c3RlcGFkbWlu!"]
        },
        /*       {
                   name: 'Listed',
                   link: '/listed',
                   fa_icon: 'fa-sharp fa-solid fa-list-ul',
                   disabled: true
               },*/
        {
            name: 'Shipping',
            link: '/shipping',
            fa_icon: 'fa-solid fa-dolly'
        },
        {
            name: 'Sold',
            link: '/sold',
            fa_icon: 'fa-solid fa-money-bill-wave'
        },
        {
            name: 'Wtbs',
            link: '/wtbs',
            fa_icon: 'fa-solid fa-solid fa-store'
        },
        {
            name: 'Sell',
            link: '/sell',
            fa_icon: 'fa-solid fa-money-bill-trend-up'
        },
        {
            name: 'Incomes/Expenses',
            link: '/incomes-expenses',
            fa_icon: 'fa-solid fa-scale-unbalanced'
        },
        {
            name: 'Settings',
            link: '/settings',
            fa_icon: 'fa-solid fa-gear'
        },
    ];

    public get getNavigation(): Array<Navigator> {
        return this.navigation
    }

    getNameByUrl(url: string): string | undefined {
        return this.navigation.find(e => e.link === url)?.name;
    }

}