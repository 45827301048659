import React, {useState} from 'react';
import './Input.scss';
import Select from "../select/Select";

let CronTypes = ['Days', "Month", "Months"];
interface CronInfo {
    type: string,
    days: {
        every: number,
        at: string
    },
    month: {
        every: number,
        onDay: number,
        at: string
    },
    months: {
        every: number,
        at: string
    }
}

interface InputProps {
    id: string;
    placeholder?: string;
    onChange: (value: any) => void;
    customClass?: string;
    customClassContainer?: string;
    disabled?: boolean;
    defaultValue?: string | number;
    type?: string,
    icon?: any,
    iconText?: string,
    iconClick?: () => void;
    showIconSelect?: boolean;
    valueSelect?: Array<any>;
    defaultValueSelect?: any;
    onChangeSelect?: (value: string) => void;
    error?: string;
}

const Input: React.FC<InputProps> = (props) => {

    const [date, setDate] = useState(props.defaultValue || '');
    const [dateCron, setDateCron] = useState<CronInfo>({
        type: CronTypes[0],
        days: {
            every: 1,
            at: "00:00"
        },
        month: {
            every: 1,
            onDay: 1,
            at: "00:00"
        },
        months: {
            every: 1,
            at: "00:00"
        }
    });
    const [price, setPrice] = useState(props.defaultValue || '');

    if (props.type === "date") {
        const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {

                let value = (e.target.value.length > (date as string).length || e.target.value.length < 9) ? e.target.value : "";
                value = value.replace(/\D/g, '');
                let formattedDate = '';
                if (value.length > 0) {
                    formattedDate = value.substring(0, 2);
                }
                if (value.length > 2) {
                    formattedDate += '-' + value.substring(2, 4);
                }
                if (value.length > 4) {
                    formattedDate += '-' + value.substring(4, 8);
                }

                setDate(formattedDate);
                props.onChange(formattedDate);

        }

        return (
            <>
                <input type="text"
                            className={`${props.customClass || ""} form-control inputCommon ${props.error ? "border-danger" : ""}`}
                            id={props.id}
                            placeholder={props.placeholder}
                            onChange={(e) => handleInput(e)}
                            value={date}
                            disabled={props.disabled}
                />
                {props.error && <span className={"text-danger inputError"}>{props.error}</span>}
            </>
        );
    }

    if (props.type === "dateCron") {

        let _dateCron = dateCron;

        const handleInput = (e?: React.ChangeEvent<HTMLInputElement>, type?: string) => {
            let _value = e!.target.value ?? "";
            let _type = e!.target.id;
            (_dateCron as any)[type!.toLowerCase()][_type] = Number(_value);
            props.onChange(Number(_value));
        }

        return (
            <>
                <div className={"d-flex align-items-center justify-content-start"}>
                    <span>Every</span>
                    {
                        dateCron.type === "Days" &&
                        <>
                            <input type="number"
                                   className={`${props.customClass || ""} cronWidth form-control inputCommon me-1 ms-1 ${props.error ? "border-danger" : ""}`}
                                   id={'every'}
                                   onChange={(e) => handleInput(e, "Days")}
                                   value={dateCron.days.every ?? 1}
                                   disabled={props.disabled}
                                   min={1}
                            />
                            <div className={"text-white"}>Days</div>
                        </>
                    }
                </div>

                {props.error && <span className={"text-danger inputError"}>{props.error}</span>}
            </>
        );
    }

    if (props.type === "price") {
        const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length > 7 && !(e.target.value.includes(",") || e.target.value.includes("."))) {
                return;
            }
            const numericValue = e.target.value.replace(/[^0-9.,]/g, '');
            const standardizedValue = numericValue.replace('.', ',');
            if (standardizedValue.split(",").length > 2) {
                return;
            }
            if (standardizedValue.includes(',')) {
                if (standardizedValue.split(',')[1].length <= 2) {
                    setPrice(standardizedValue);
                    props.onChange(standardizedValue); 
                }  
            } else {
                setPrice(standardizedValue);
                props.onChange(standardizedValue);   
            }
        }

        return (
            <>
                <div className={`${props.customClassContainer || ""} inputContainer d-flex justify-content-center align-items-center`} key={"inputPrice"}>
                    <input type="text"
                            className={`${props.customClass || ""} form-control inputCommon ${props.error ? "border-danger" : ""}`}
                            id={props.id}
                            placeholder={props.placeholder}
                            onChange={(e)=>{handleInput(e)}}
                            value={price}
                            disabled={props.disabled}
                    />
                    {<div className={"inputPrice cursor-pointer price"}>
                        {props.onChangeSelect && props.valueSelect &&
                            <Select selectedValue={props.defaultValueSelect} disabled={props.disabled} value={props.valueSelect} customClass={`priceSelect ${props.error ? "border-danger" : ""}`} id={"inputPrice"} onChange={(str)=>props.onChangeSelect!(str)}/>
                        }
                    </div>}
                </div>
                {props.error && <span className={"text-danger inputError"}>{props.error}</span>}
            </>
        );
    }

    return (
        <>
            <div className={`${props.customClassContainer || ""} inputContainer`}>
                {(props.icon || props.iconText) && <div className={"inputIcon cursor-pointer"} onClick={()=>props.iconClick && props.iconClick()}>
                    <div className={"text-center text-white mt-2"}>
                        {props.icon && <i className={props.icon}></i>}
                        {props.iconText && <span>{props.iconText}</span>}
                    </div>
                </div>}
                <input type="text"
                        className={`${props.customClass || ""} form-control inputCommon ${props.error ? "border-danger" : ""}`}
                        style={(props.icon || props.iconText) ? {paddingRight: 42} : {}}
                        id={props.id}
                        placeholder={props.placeholder}
                        onChange={event => props.onChange(event.target.value)}
                        value={props.defaultValue}
                        disabled={props.disabled}
                />
                {props.error && <span className={"text-danger inputError"}>{props.error}</span>}
            </div>
        </>
    );
}


export default Input;
