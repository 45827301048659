import React from 'react';
import { cssTransition, toast } from "react-toastify";
import './toastStyle.scss';
import LogoImage from '../../assets/images/logo/transparent-logo.png';

interface ToastProps {
    id?: string,
    message: string
}

export default class ToastHandler {

    private static animation = cssTransition({
        enter: "slide-in-elliptic-bottom-fwd",
        exit: "scale-out-center"
    });


    public static dispatchToast(params?: any, type?: string) {
        if (type) {
            switch (type) {
                case 'success':
                    break;
                case 'warning':
                    break;
                case 'error':
                    break;
                default:
                    this.customToast(params);
            }
        }
    }

    public static customToast(params: ToastProps) {
        const Msg = () => (
            <div className='d-flex justify-content-start align-items-center pb-1'>
                <img src={LogoImage} className='imgToastLogo1' alt='ToastLogo' />
                <div className='d-flex justify-content-between align-items-center ps-1 pe-1 w-100 toastContentText'>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <span className={"toastMessage"}>{params.message || 'StepUp Toast!'}</span>
                    </div>
                </div>
            </div>
        )

        toast(<Msg />, {
            position: "bottom-right",
            toastId: params?.id || Math.floor(Math.random() * (1000 - 1 + 1) + 1),
            transition: this.animation,
            className: "toastCustom",
            progressClassName: "toastProgressCustom",
            closeButton: false,
            autoClose: 2500
        });
    }
}