import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import '../CommonModal.scss';
import './SaleItemModal.scss';
import Button from "../../common/button/Button";
import { formatPrice, getDateByTimeStamp } from "../../../utility/commonUtils";
import Logo from "../../../assets/images/logo/transparent-logo.png";
import Input from "../../common/input/Input";
import { InventoryResultInterface, Sale } from "../../../services/InventoryInterfaces";
import Select from "../../common/select/Select";
import UserDataStorage from "../../../singletonStorage/userDataStorage";
import InventoryListService from "../../../services/inventory/InventoryListService";
import ToastHandler from "../../../utility/ToastHandler/ToastHandler";
import moment from "moment/moment";
import Switch from "../../common/switch/Switch";
const _ = require('lodash');


interface SaleItemModalModalProps {
    show: boolean;
    onHide: (didChange?: boolean) => void
    item: InventoryResultInterface;
}

const SaleItemModal: React.FC<SaleItemModalModalProps> = (props) => {

    const inventoryService = new InventoryListService();
    const userData = UserDataStorage.getInstance();

    const [pendingChanges, setPendingChanges] = useState<boolean>(false);
    const [showWarningClose, setShowWarningClose] = useState<boolean>(false);
    const [item, setItem] = useState<Sale | undefined>(props.item.sale);

    const [errors, setErrors] = useState({
        saleDate: "",
        saleStore: "",
        salePrice: ""
    })

    const saveChanges = async () => {
        if (!item || !item.store || !item?.price?.value || !item.saleDate) {
            setErrors(() => {
                return {
                    saleStore: !item?.store ? "Sale Store is required" : "",
                    salePrice: !item?.price?.value ? "Sale Price is required" : "",
                    saleDate: !item?.saleDate ? "Invalid Date" : "",
                }
            }
            )
            return;
        } else {
            setErrors(() => ({ saleDate: "", salePrice: "", saleStore: "" }))
        }
        await inventoryService.updatePurchase(props.item.Id, { sale: item }).then(e => {
            ToastHandler.customToast({ message: "Sale Updated" });
            props.onHide(true);
        })
    }

    const handleClose = (forceClose?: boolean) => {
        if (forceClose) {
            setShowWarningClose(false);
            setPendingChanges(false);
            props.onHide();
            return;
        }
        if (pendingChanges) {
            setShowWarningClose(true);
            return;
        }
        props.onHide();
    }

    const updateItem = (fieldToUpdate: any) => {

        const setError = (type: string, message: string) => {
            setErrors((prev) => {
                return {
                    ...prev,
                    [type]: message
                };
            });
        }
        let fields = fieldToUpdate;
        if (fields && fields['saleDate'] !== undefined && fields['saleDate'] !== "") {
            const validDate = fields['saleDate'] ? moment(fields['saleDate'], 'DD-MM-YYYY', true).isValid() : true;
            if ((!validDate || fields['saleDate'] === '') && (fields['saleDate'] && fields['saleDate'].length > 9)) {
                setError("saleDate", "Missing or Incorrect Date, insert correct format: DD-MM-YYYY");
                return;
            } else {
                setError("saleDate", "");
            }
            fields['saleDate'] = moment(fields['saleDate'], 'DD-MM-YYYY', true).toDate().getTime();
        }
        setItem((item: any) => {
            if (item) {
                setPendingChanges(true);
                return { ..._.merge(item, fieldToUpdate) };
            } else {
                return { ..._.merge(fieldToUpdate) };
            }
        })
    }


    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName={"commonModal"}
            backdropClassName={"commonModalBackdrop"}
        >
            <Modal.Body>
                <>
                    <>
                        {showWarningClose && <div className={"closeConfirm"}>
                            <div className={"w-100 h-100 p-1 color-white d-flex flex-column justify-content-center align-items-center"}>
                                <div className={"w-100 p-2 mb-2 text-center message"}>You have pending changes, are you sure you want to close?</div>
                                <div className={"d-flex justify-content-center align-items-center"}>
                                    <Button customClass={"closeConfirmButtons btn-dark"} id={"discard"} onClick={() => setShowWarningClose(false)} value={"Discard"} />
                                    <Button customClass={"closeConfirmButtons bg-danger btn-danger"} id={"close"} onClick={() => handleClose(true)} value={"Close"} />
                                </div>
                            </div>
                        </div>}
                        <div className={`viewOrEditItemModal ${showWarningClose ? "blurred" : ""}`}>
                            <div className={'containerModal'}>
                                <div className={"closeButton"} onClick={() => handleClose()}>
                                    <i className="fa-solid fa-xmark"></i>
                                </div>
                                <div className="h6 w-100 text-center text-white">
                                    Sell Item
                                </div>
                                <div className={"mt-2 ps-3 pe-3 pt-4 pb-4 color-white"}>
                                    <div className="productDetailView">
                                        <img className="p-1 rounded-3" src={props.item.item.imgUrl}
                                            onError={({ currentTarget }) => {
                                                try { currentTarget.onerror = null; currentTarget.src = Logo; } catch (e) { }
                                            }}
                                            width={80} height={80} alt={"jordan"} />
                                        <div className={"w-100 d-flex justify-content-between"}>
                                            <div className={"text-right ps-1 pe-1"}>
                                                <div className={"brand"}>{props.item.item.brandId}</div>
                                                <div className={"name"}>{props.item.item.name}</div>
                                                <div className={"sku"}>{props.item.item.sku}</div>
                                            </div>
                                            <div className={"d-flex ps-1 pe-1 align-items-center"}>
                                                <div className={"size pe-2"}>{(props.item.variant.size as any)[(userData.settingsStorage!.sizeCountry)]} {userData.settingsStorage!.sizeCountry.toUpperCase()}</div>
                                                <div className={"ps-2 pe-2"}>
                                                    {
                                                        Object.entries(props.item.variant.size).map((e, i) => {
                                                            if (e[0] === userData.settingsStorage!.sizeCountry) {
                                                                return <></>;
                                                            }
                                                            return <div key={i} className={"sizeMini"}>{(props.item.variant.size as any)[(e[0])]} {e[0].toUpperCase()}</div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row justify-content-between mt-3"}>
                                        <div className={"col-6 col-sm-3"}>
                                            <label className={"labelModal"} htmlFor={"saleDate"}>Sale Date</label>
                                            <Input defaultValue={item?.saleDate ? getDateByTimeStamp(item?.saleDate, true) : ""} error={errors.saleDate} type={"date"} customClass={"smallPlaceholder"} id={"saleDate"} placeholder={"DD-MM-YYYY"} onChange={(str) => updateItem({ saleDate: str })} />
                                        </div>
                                        <div className={"col-6 col-sm-3"}>
                                            <label className={"labelModal"} htmlFor={"saleStore"}>Sale Store</label>
                                            <Select selectedValue={item?.store} value={userData.sessionStorage!['marketplaces']} error={errors.saleStore} customClass={"smallPlaceholder w-100"} id={"saleStore"} onChange={(str) => updateItem({ store: str })} />
                                        </div>
                                        <div className={"col-6 col-sm-3"}>
                                            <label className={"labelModal"} htmlFor={"salePrice"}>Sale Price</label>
                                            <Input id={"purPrice"}
                                                type={"price"}
                                                error={errors.salePrice}
                                                onChangeSelect={(str) => {
                                                    updateItem({
                                                        ...{
                                                            price: {
                                                                currency: str,
                                                                value: item?.price.value || userData.sessionStorage!['currencies']
                                                            }
                                                        }
                                                    })
                                                }}
                                                valueSelect={userData.sessionStorage!['currencies']}
                                                defaultValue={formatPrice(item?.price?.value)}
                                                defaultValueSelect={item?.price?.currency || userData.settingsStorage!['currency']}
                                                onChange={(str) => updateItem({
                                                    price: {
                                                        currency: item?.price?.currency || userData.settingsStorage!['currency'],
                                                        value: Number(Number(str?.replaceAll(',', '.')).toFixed(2))
                                                    }
                                                })
                                                }
                                            />
                                        </div>
                                        <div className={"col-6 col-sm-3"}>
                                            <label className={"labelModal"} htmlFor={"saleID"}>Sale ID</label>
                                            <Input defaultValue={item?.saleId} customClass={"smallPlaceholder"} id={"saleID"} onChange={(str) => updateItem({ saleId: str })} />
                                        </div>
                                    </div>
                                    <div className={"row justify-content-between mt-2"}>
                                        <div className={"col-6 col-sm-3"}>
                                            <label className={"labelModal"} htmlFor={"courier"}>Courier</label>
                                            <Select id="courier" selectedValue={item?.ship?.courierId || 'Auto'} value={['Auto', ...userData.sessionStorage!['couriers']]} placeholder="Courier" onChange={(str) => updateItem({ ship: { courierId: str } })} />
                                        </div>
                                        <div className={"col-10 col-sm-3"}>
                                            <label className={"labelModal"} htmlFor={"tracking"}>Tracking</label>
                                            <Input defaultValue={item?.ship?.trakingCode || ""} customClass={"smallPlaceholder"} id={"tracking"} onChange={(str) => updateItem({ ship: { trakingCode: str } })} />
                                        </div>
                                        <div className={"col-2 col-sm-3"}>
                                            <label className={"labelModal"} htmlFor={"paid"}>Paid</label>
                                            <Switch id={"paid"} value={item?.paied} customClass={"mt-2"} onChange={(bool) => { updateItem({ paied: bool }) }} />
                                        </div>
                                    </div>
                                    <div className={"mt-3 w-100 pe-1"}>
                                        <div>
                                            <label htmlFor="notes" className="labelModal mb-0">Notes</label>
                                        </div>
                                        <textarea defaultValue={item?.saleNotes} className="text-area-settings w-100" id="notes" onChange={(event) => updateItem({ saleNotes: event.target.value })}></textarea>
                                    </div>
                                    <div className={"w-100 d-flex justify-content-end align-items-center mt-2"}>
                                        <Button id={"viewModalSave"} onClick={() => { saveChanges() }} value={"Save"} customClass={"me-2"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </>
            </Modal.Body>
        </Modal>
    );
}

export default SaleItemModal;