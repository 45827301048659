import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardInterface } from '../utils/card_shower';
import './Card.scss';
import UserDataStorage from '../../../../../singletonStorage/userDataStorage';
import { removeDuplicatesFromArray } from "../../../../../utility/commonUtils";

interface CardProps {
    card: CardInterface;

}

const Card: React.FC<CardProps> = ({ card }: CardProps) => {

    const { t } = useTranslation('dashboard');
    const userData: UserDataStorage = UserDataStorage.getInstance();

    return (
        <div className='card'>
            <span className='cardIcon'><i className={card.fa_icon}></i></span>
            <div>
                <div className='cardValue'>{card.format && card.value.includes(" ") ? removeDuplicatesFromArray((card.value.split(" ")[0] + " " + userData.settingsStorage!['currency']).split(userData.settingsStorage!['currency'])).join(userData.settingsStorage!['currency']) : removeDuplicatesFromArray(card.value.toString().split(userData.settingsStorage!['currency'])).join(userData.settingsStorage!['currency'])}</div>
                <div className='cardName'>{t(card.name)}</div>
            </div>
        </div>
    );
}

export default Card;
