import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './Settings.scss';
import Button from "../../../common/button/Button";
//import ModulesInfo, {ModuleInterface} from "../../../../utility/modulesInfo";
import Select from "../../../common/select/Select";
//import SettingsModal from "../../../modals/SettingsModal/SettingsModal";
import UserDataStorage from "../../../../singletonStorage/userDataStorage";
import ToastHandler from "../../../../utility/ToastHandler/ToastHandler";
import Input from '../../../common/input/Input';
import { formatPrice } from '../../../../utility/commonUtils';
import Loader from '../../../common/loader/Loader';
import MultiSelect from '../../../common/multiselect/MultiSelect';


const Settings: React.FC = () => {

    const userData = UserDataStorage.getInstance();
    //const [modules, setModules] = useState<Array<ModuleInterface>>();
    //const [selectedAccount, setSelectedAccount] = useState<string>("");
    //const [show, setShow] = useState<boolean>(false);
    const [session, setSession] = useState<Record<any, any>>({});
    const [settings, setSettings] = useState<Record<any, any>>({});

    const inputElement = useRef(null);
    const inputElementInc = useRef(null);

    const [loaders, setLoaders] = useState({
        import: false,
        importInc: false,
        saveSettings: false
    })

    const [errors, setErrors] = useState({
        nationalPurchasePrice: "",
        internationalPurchasePrice: ""
    })

    const [isLoading, setLoading] = useState(true);


    const { t } = useTranslation('settings');

    useEffect(() => {
        //let moduleInfo = new ModulesInfo();
        //setModules([...moduleInfo.getModules()]);
        setSession(userData.sessionStorage ?? {});
        setSettings(formatSettings(userData.settingsStorage));
        setLoading(false);
    }, [userData]);

    const formatSettings = (settings?: Record<any, any>): Record<any, any> => {
        if (!settings) {
            return {};
        }
        if (settings?.national) {
            settings.natPurPrice = formatPrice(settings?.national?.price);
            settings.natPurCurrency = settings?.national?.currency;
            delete settings.national;
        }
        if (settings?.international) {
            settings.internatPurPrice = formatPrice(settings?.international?.price);
            settings.internatPurCurrency = settings?.international?.currency;
            delete settings.international;
        }
        return settings;
    }

    const updateSettings = (name: string, value: any) => {
        if ((name === "customPurchaseStores" || name === "customSaleStores" || name === "customService") && typeof value === "string" && value) {
            value = value.split(",");
        }
        if (name === "paymethods") {
            value = session['paymethods'].reduce(function (acc: any, cur: any) {
                acc[cur] = value.includes(cur) ? true : false;
                return acc;
            }, {});
        }
        setSettings((setting) => {
            const newSettings = {
                ...setting,
                [name]: value === "" ? [] : value
            };
            return newSettings;
        });
    }
    const sendSettings = async () => {
        setLoaders((prev) => ({ ...prev, saveSettings: true }));
        // Nasconsta proprietà webhook
        //let propertyNames = ["discordWebhook", "currency", "sizeCountry", "customPurchaseStores", "customSaleStores", "customService"];
        let propertyNames = ["currency", "sizeCountry", "customPurchaseStores", "customSaleStores", "customService", "national", "international", "nation"];
        let nationalPurchasePriceNames = ["natPurPrice", "natPurCurrency"];
        let internationalPurchasePriceNames = ["internatPurPrice", "internatPurCurrency"];

        let settingsPropertyNames = Object.entries(settings).map(e => e[0]);

        // Gestisce i prezzi di Shipping price

        if (settingsPropertyNames.includes(nationalPurchasePriceNames[0]) || settingsPropertyNames.includes(nationalPurchasePriceNames[1])) {
            let national = {
                price: settings[nationalPurchasePriceNames[0]] ? Number(Number(settings[nationalPurchasePriceNames[0]]?.replaceAll(',', '.')).toFixed(2)) : '',
                currency: settings[nationalPurchasePriceNames[1]] ?? 'EUR',
            }
            Object.assign(settings, { national: national });
            settingsPropertyNames.includes(nationalPurchasePriceNames[0]) && delete settings[nationalPurchasePriceNames[0]];
            settingsPropertyNames.includes(nationalPurchasePriceNames[1]) && delete settings[nationalPurchasePriceNames[1]];
        }

        if (settingsPropertyNames.includes(internationalPurchasePriceNames[0]) || settingsPropertyNames.includes(internationalPurchasePriceNames[1])) {
            let international = {
                price: settings[internationalPurchasePriceNames[0]] ? Number(Number(settings[internationalPurchasePriceNames[0]]?.replaceAll(',', '.')).toFixed(2)) : '',
                currency: settings[internationalPurchasePriceNames[1]] ?? 'EUR',
            }
            Object.assign(settings, { international: international });
            settingsPropertyNames.includes(internationalPurchasePriceNames[0]) && delete settings[internationalPurchasePriceNames[0]];
            settingsPropertyNames.includes(internationalPurchasePriceNames[1]) && delete settings[internationalPurchasePriceNames[1]];
        }

        let _settings = settings;
        for (let i = 0; i < propertyNames.length; i++) {
            if (!_settings[propertyNames[i]]) {
                Object.assign(_settings, { [propertyNames[i]]: null });
            }
        }
        await userData.updateSettings(settings).then(() => {
            setLoaders((prev) => ({ ...prev, saveSettings: false }));
            userData.getUserSession();
            ToastHandler.customToast({ message: "Settings Updated Successfully" })
        });
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoaders((prev) => ({ ...{ ...prev, import: true } }));
        if (event.target.files && event.target.files[0] && event.target.files[0].type === 'text/csv') {
            let file = event.target.files[0];
            const formData = new FormData();
            formData.append('myFile', file);
            userData.importFile(formData).then((e) => {
                setLoaders((prev) => ({ ...{ ...prev, import: false } }));
                if (e.includes("Successfully imported all items")) {
                    ToastHandler.customToast({ message: "File imported successfully" })
                } else {
                    const blob = new Blob([e], { type: 'plain/text' });
                    const href = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'StepUpFile.csv');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    ToastHandler.customToast({ message: "File not imported or partially imported due to bad format" })
                }
            }).catch(() => {
                setLoaders((prev) => ({ ...{ ...prev, import: false } }));
                ToastHandler.customToast({ message: "File not imported or partially imported due to bad format" })
            });
        } else {
            setLoaders((prev) => ({ ...{ ...prev, import: false } }));
            ToastHandler.customToast({ message: "Empty or Wrong Format File" })
        }
    }

    const handleFileChangeInc = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoaders((prev) => ({ ...{ ...prev, importInc: true } }));
        if (event.target.files && event.target.files[0] && event.target.files[0].type === 'text/csv') {
            let file = event.target.files[0];
            const formData = new FormData();
            formData.append('myFile', file);
            userData.importFileIncomesExpenses(formData).then((e) => {
                setLoaders((prev) => ({ ...{ ...prev, importInc: false } }));
                if (e.includes("Successfully imported all items")) {
                    ToastHandler.customToast({ message: "File imported successfully" })
                } else {
                    const blob = new Blob([e], { type: 'plain/text', });
                    const href = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.setAttribute('download', 'Income-Expenses.csv');
                    link.href = href;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    ToastHandler.customToast({ message: "File not imported or partially imported due to bad format" })
                }
            }).catch(() => {
                setLoaders((prev) => ({ ...{ ...prev, importInc: false } }));
                ToastHandler.customToast({ message: "File not imported or partially imported due to bad format" })
            });
        } else {
            setLoaders((prev) => ({ ...{ ...prev, importInc: false } }));
            ToastHandler.customToast({ message: "Empty or Wrong Format File" })
        }
    }

    const importFile = () => {
        setLoaders((prev) => ({ ...{ ...prev, import: true } }));
        if (inputElement && inputElement.current) {
            (inputElement.current as any).click();
        }
    }

    const exportFile = () => {
        userData.exportFile().then((e) => {
        });
    }

    const importFileInc = () => {
        setLoaders((prev) => ({ ...{ ...prev, importInc: true } }));
        if (inputElementInc && inputElementInc.current) {
            (inputElementInc.current as any).click();
        }
    }

    const exportFileInc = () => {
        userData.exportFileIncomesExpenses().then((e) => {
        });
    }

    return (
        <>
            <div className='settings'>
                {/* <div className='mt-2'>
                    <label htmlFor="webhook" className='section-title'>Webhook</label>
                    <div className="d-flex">
                        <Input id="webhook" customClassContainer="w-75" onChange={(value: string) => {updateSettings("discordWebhook", value)}} defaultValue={settings.discordWebhook}></Input>
                        <Button id="accountButton" customClass="ms-2" value="Test" onClick={() => {console.log('Click')}}></Button>
                    </div>
                </div> */}
                {isLoading && <div className='inventoryContainer d-flex justify-content-center align-items-center w-100'>
                    {<Loader bootstrapLoader customStyle={{ width: '36px', height: '36px', marginTop: '50px' }} />}
                </div>}
                {!isLoading && <div>
                    <div className="mt-5">
                        <div className='d-flex justify-content-start align-items-center'>
                            <div className='d-flex flex-column me-3'>
                                <label htmlFor="currency" className="section-title">{t('currency')}</label>
                                <Select id="currency" selectedValue={settings['currency']} value={session['currencies']} onChange={(value) => updateSettings("currency", value)}></Select>
                            </div>
                            <div className='d-flex flex-column ms-3'>
                                <label htmlFor="sizeFormat" className="section-title">{t('sizeFormat')}</label>
                                <Select id="sizeFormat" selectedValue={settings['sizeCountry']} value={['EU', 'US', 'UK', 'CM']} onChange={(value) => updateSettings("sizeCountry", value)}></Select>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12 col-md-3'>
                            <label htmlFor="nation" className='section-title mt-4'>Nation</label>
                            <div className={"w-100 d-flex justify-content-start mt-1"}>
                                <Select id="nation" selectedValue={settings['nation']} value={session['countries']} onChange={(value) => updateSettings("nation", value)}></Select>
                            </div>
                        </div>
                        <div className='col-12 col-md-3'>
                            <label htmlFor="paymethods" className='section-title mt-4'>Payment Methods</label>
                            <div className={"w-100 d-flex justify-content-start mt-1"}>
                                <MultiSelect customClass="w-100 ps-2 paymentMethodSelect"
                                    buttonCustomClass='h-auto'
                                    placeholder={settings['paymethods'] && Object.entries(settings['paymethods']).filter(e => e[1] === true).length > 0 ? Object.entries(settings['paymethods']).filter(e => e[1] === true)!.map((_e: any) => _e[0]).sort((a, b) => a - b).join(' - ') : 'Select a method'} id="paymethods"
                                    selectedValue={settings['paymethods'] ? Object.entries(settings['paymethods']).filter(e => e[1] === true)!.map((_e: any) => _e[0]) : []} value={session['paymethods']} onChange={(value) => updateSettings("paymethods", value)} />
                            </div>
                        </div>
                        <div className='col-12 col-md-5'>
                            <label htmlFor="nationalPurPrice" className='section-title mt-4'>Shipping Prices</label>
                            <div className={"row d-flex justify-content-start mt-1"}>
                                <div className={"col-12 col-sm-5 my-1 d-flex align-items-center"}>
                                    <label className={"labelModal color-white pe-2"} htmlFor={"nationalPurPrice"}>National</label>
                                    <Input error={errors.nationalPurchasePrice} customClass={"smallPlaceholder w-100"} id={"nationalPurPrice"}
                                        type={"price"}
                                        onChangeSelect={(value: string) => updateSettings('natPurCurrency', value)}
                                        defaultValue={settings['natPurPrice'] ?? 0}
                                        defaultValueSelect={settings['natPurCurrency'] ?? 'EUR'}
                                        valueSelect={userData.sessionStorage!.currencies}
                                        onChange={(value: string) => updateSettings('natPurPrice', value)}
                                    />
                                </div>
                                <div className={"col-12 col-sm-7 my-1 d-flex align-items-center"}>
                                    <label className={"labelModal color-white pe-2"} htmlFor={"internationalPurPrice"}>International</label>
                                    <Input error={errors.internationalPurchasePrice} customClass={"smallPlaceholder w-100"} id={"internationalPurPrice"}
                                        type={"price"}
                                        onChangeSelect={(value: string) => updateSettings('internatPurCurrency', value)}
                                        defaultValue={settings['internatPurPrice'] ?? 0}
                                        defaultValueSelect={settings['internatPurCurrency'] ?? 'EUR'}
                                        valueSelect={userData.sessionStorage!.currencies}
                                        onChange={(value: string) => updateSettings('internatPurPrice', value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className='row justify-content-start align-items-center'>
                            <div className='col-12 col-sm-3 d-flex flex-column'>
                                <label htmlFor="recPurchaseStore" className="section-title"><span>{t('recPurchaseStore')}<br />{t('separatedComma')}</span></label>
                                <textarea className="text-area-settings" id="recPurchaseStore" onChange={(e) => updateSettings("customPurchaseStores", e.target.value)} defaultValue={settings.customPurchaseStores}></textarea>
                            </div>
                            <div className='col-12 col-sm-3 d-flex flex-column'>
                                <label htmlFor="recSaleStore" className="section-title"><span>{t('recSaleStore')}<br />{t('separatedComma')}</span></label>
                                <textarea className="text-area-settings" id="recSaleStore" onChange={(e) => updateSettings("customSaleStores", e.target.value)} defaultValue={settings.customSaleStores}></textarea>
                            </div>
                            <div className='col-12 col-sm-3 d-flex flex-column'>
                                <label htmlFor="recServicesStore" className="section-title"><span>{t('recServicesStore')}<br />{t('separatedComma')}</span></label>
                                <textarea className="text-area-settings" id="recServicesStore" onChange={(e) => updateSettings("customService", e.target.value)} defaultValue={settings.customService}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12 col-md-3'>
                            <label htmlFor="webhook" className='section-title mt-4'>Import/Export Inventory</label>
                            <div className={"w-100 d-flex justify-content-start mt-1"}>
                                <Button id={"import"} customClass={"me-3"} onClick={() => importFile()} value={"Import"} shouldLoad={loaders.import}></Button>
                                <Button id={"export"} onClick={() => exportFile()} value={"Export"}></Button>
                                <input className='d-none' type="file" ref={inputElement} id="myFile" onClick={(e: any) => (e.target.value = null)} onChange={(event) => handleFileChange(event)} />
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <label htmlFor="webhook" className='section-title mt-4'>Import/Export Incomes Expenses</label>
                            <div className={"w-100 d-flex justify-content-start mt-1"}>
                                <Button id={"importInc"} customClass={"me-3"} onClick={() => importFileInc()} value={"Import"} shouldLoad={loaders.importInc}></Button>
                                <Button id={"exportInc"} onClick={() => exportFileInc()} value={"Export"}></Button>
                                <input className='d-none' type="file" ref={inputElementInc} id="myFileInc" onClick={(e: any) => (e.target.value = null)} onChange={(event) => handleFileChangeInc(event)} />
                            </div>
                        </div>
                    </div>
                    <div className={"w-100 d-flex justify-content-center mt-4 mb-4"}>
                        <Button id={"saveSettings"} onClick={() => sendSettings()} value={"Save Settings"} shouldLoad={loaders.saveSettings}></Button>
                    </div>
                    {
                        process.env.REACT_APP_ENV !== "prod" &&
                        <div className='mt-2'>
                            <label htmlFor="webhook" className='section-title mt-4'>Dev Utils</label>
                            <div className={"w-100 d-flex justify-content-start align-items-center mt-1"}>
                                <div className="section-title align-items-center">FireBase Messaging Token:</div>
                                <div className='text-white w-100' style={{ overflowWrap: 'anywhere' }}>{userData.sessionFireBaseToken ?? 'No token found, check if you have permissions'}</div>
                            </div>
                        </div>
                    }
                </div>
                }
            </div >

            {/* <SettingsModal
                show={show}
                type={selectedAccount}
                onHide={()=>setShow(false)}
            /> */}
        </>
    );
}


export default Settings;