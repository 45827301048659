import React, { useMemo, useState } from 'react';
import './Filters.scss';
import Input from "../input/Input";
import MultiSelect from '../multiselect/MultiSelect';
import UserDataStorage from '../../../singletonStorage/userDataStorage';
import DatePickerComponent from '../datepicker/DatePicker';
import Button from '../button/Button';
import { InventoryFilters } from '../../../services/inventory/InventoryFilterInterfaces';
import { ListedFilters } from '../../../services/listed/ListedFilterInterfaces';
import { ShippingFilters } from '../../../services/shipping/ShippingFilterInterfaces';
import { SoldFilters } from '../../../services/sold/SoldFilterInterfaces';
import { ExpenseIncomeFilters } from '../../../services/expenseincome/ExpenseIncomeFilterInterfaces';
import MultiSelectSizes from '../multiselectsizes/MultiSelectSizes';
import { roleCheck, typeCheck } from '../../../utility/roleChecker';
import CustomModal from '../../modals/CustomModal/CustomModal';
import Loader from '../loader/Loader';
import Logo from "../../../assets/images/logo/transparent-logo.png";
import ToastHandler from '../../../utility/ToastHandler/ToastHandler';
import { jwtDecode } from 'jwt-decode';
import { m } from 'framer-motion';
import InventoryListService from '../../../services/inventory/InventoryListService';

interface FiltersProps {
    openModal: () => void;
    type: string;
    onChange?: (args: any) => void;
    showDeleteSelectedTasks?: boolean,
    deleteSelectedTasks?: () => void
    //PER INVENTORY
    export?: () => void;
}

const Filters: React.FC<FiltersProps> = (props) => {

    const userData = UserDataStorage.getInstance();

    const urlDiscordPlan = 'https://whop.com/hub/stepup/discord-T0pjKb6C57TZ8D/app/';

    const [isLoadingInApp, setIsLoading] = useState<boolean>(false);
    const [showCustomModal, setShowCustomModal] = useState(false);
    const [showCustomModalChildren, setShowCustomModalChildren] = useState();

    const [filtersState, setFiltersState] = useState<InventoryFilters | ListedFilters | ShippingFilters | SoldFilters>();

    const updateFilters = (id: string, value: any) => {
        let updated: any = {};
        setFiltersState((prev) => {
            updated = {
                ...prev,
                [id]: value
            }
            if (props.onChange) {
                props.onChange(updated);
            }
            return updated;
        })
    }

    const clearFilters = () => {
        setFiltersState({});
        if (props.onChange) {
            props.onChange({});
        }
    }

    const checkForFunctions = (type: string) => {

        const available = typeCheck(type)
        if (!available) {
            switch (type) {
                case 'nation':
                    ToastHandler.customToast({message: 'In order to add a WTB request, you need to fill the "Nation" field in your profile settings'}) 
                break;
                default: 
                break;
            }
        }

        return available;
    }

    const planClaim = async (role: string) => {
        setIsLoading(true)
        return await userData.refreshRoles().then(e => {
            const jwt: any = jwtDecode(e?.jwt);
            if (jwt && jwt.roles && jwt.roles.includes(role)) {
                userData.getUser().then(async () => {
                    userData.getUserSession().then(() => {
                        setTimeout(() => {
                            setShowCustomModal(false);
                            setShowCustomModalChildren(<></> as any);
                            setIsLoading(false);
                            switch (role) {
                                case 'DiscordClaimed': 
                                    checkForFunctions('nation') && props.openModal();
                                break;
                                default:
                                break;
                            }
                        }, 200);
                    })
                }).catch((err: any) => {
                    if (err?.response?.data && err?.response?.data?.errorMessage === "Invalid AuthToken") {
                        localStorage.clear();
                        setIsLoading(false);
                        ToastHandler.customToast({ message: "Invalid login, please logout and retry" })
                        return;
                    }
                    if (err?.response?.data && err?.response?.data?.errorCode === 418) {
                        setIsLoading(false);
                        ToastHandler.customToast({ message: "It looks like you don't have permission, you need a plan in order to use StepUp" })
                        return
                    }
                    ToastHandler.customToast({ message: "Error during login. Retry later" })
                });
            } else {
                setIsLoading(false);
                ToastHandler.customToast({ message: "It looks like you don't have permission, you need to connect to Discord in order to use this feature" })
            }
        }).catch(e => {
            setIsLoading(false);
            ToastHandler.customToast({ message: "It looks like you don't have permission, you need to connect to Discord in order to use this feature" })
        })
    }

    const checkRole = (role: string) => {

        const available = roleCheck(role);
        if (!available) {
            switch (role) {
                case 'DiscordClaimed':
                    {
                        const children: any = <>
                            <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white'>
                                <div className='loginPrompt'>
                                    {!isLoadingInApp &&
                                        <div className="w-100 d-flex justify-content-center align-items-center">
                                            <img src={Logo} width={60} height={60} alt='StepUp Logo' />
                                            <strong className='ms-2'>{process.env.REACT_APP_NAME}</strong>
                                        </div>
                                    }
                                    <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                                        {isLoadingInApp && <Loader />}
                                        {isLoadingInApp && <span className='my-2 welcomeLoading'>StepUp is loading...</span>}
                                        {!isLoadingInApp && <span className='my-2 text-center p-2'>
                                            To use this feature, you need your account connected to our Discord server.
                                            You can do that <a className='fw-bold' href={urlDiscordPlan} rel="noreferrer" target='_blank'>here</a>,
                                            then return to this page and press the button below
                                        </span>}
                                        {!isLoadingInApp && <div className='d-flex align-items-center'>
                                            <Button id='discordClaim' shouldLoad={isLoadingInApp} value={"Discord claimed"} customClass='mx-1' onClick={() => planClaim(role)} />
                                            <Button id='close' value={"Close"} customClass='mx-1' onClick={() => setShowCustomModal(false)} />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </>
                        setShowCustomModalChildren(children)
                        setShowCustomModal(true);
                        break;
                    }
                default: break;
            }
        }
        return available;

    }

    const inventoryFilters = <>
        <div className='add' onClick={() => props.openModal()}>
            <i className="fa-solid fa-plus"></i>
        </div>
        <Input id="searchFilters" defaultValue={(filtersState as InventoryFilters)?.searchFilters} customClass="searchFilters ms-3" placeholder="Search..." onChange={(str) => updateFilters('searchFilters', str)} />
        <MultiSelectSizes id="sizeFilter" selectedValue={(filtersState as InventoryFilters)?.sizeFilter} value={userData.sessionStorage!['allSizes'][userData!.settingsStorage!['sizeCountry']]} customClass='selectFilters ms-3' placeholder="Size" onChange={(str) => updateFilters('sizeFilter', str)} />
        <DatePickerComponent defaultValue={(filtersState as InventoryFilters)?.purDateFilter} id="purDateFilter" customClass="selectFilters ms-3" placeholder="Purchase Date" onChange={(str) => updateFilters('purDateFilter', str)} />
        <MultiSelect id="purStoreFilter" selectedValue={(filtersState as InventoryFilters)?.purStoreFilter} value={userData.sessionStorage!['retailers']} customClass='selectFilters ms-3' placeholder="Purchase Store" onChange={(str) => updateFilters('purStoreFilter', str)} />
        {/* <Button id="exportInventory" customClass='selectFilters ms-3 pe-2 text-center' onClick={() =>props.export!()}>
        <div>Export <i className="fa-solid fa-file-export"></i></div>
        </Button> */}
        <Button id="hideDuplicates" customClass='selectFilters ms-3 pe-2 text-center' onClick={() => { updateFilters('hideDuplicates', !(filtersState as InventoryFilters)?.hideDuplicates) }}>
            <div>{(filtersState as InventoryFilters)?.hideDuplicates ? 'Show' : 'Hide'} Duplicates <i className={`fa-solid fa-eye${!(filtersState as InventoryFilters)?.hideDuplicates ? '-slash' : ''} h6 mb-0 pt-1`}></i></div>
        </Button>
    </>;

    const wtbsFilters = <>
        <div className='add' onClick={() => { checkRole('DiscordClaimed') && checkForFunctions('nation') && props.openModal() }}>
            <i className="fa-solid fa-plus"></i>
        </div>
        <Input id="searchFilters" defaultValue={(filtersState as InventoryFilters)?.searchFilters} customClass="searchFilters ms-3" placeholder="Search..." onChange={(str) => updateFilters('searchFilters', str)} />
        <MultiSelectSizes id="sizeFilter" selectedValue={(filtersState as InventoryFilters)?.sizeFilter} value={userData.sessionStorage!['allSizes'][userData!.settingsStorage!['sizeCountry']]} customClass='selectFilters ms-3' placeholder="Size" onChange={(str) => updateFilters('sizeFilter', str)} />
        <Button id="hideDuplicates" customClass='selectFilters ms-3 pe-2 text-center' onClick={() => { updateFilters('hideDuplicates', !(filtersState as InventoryFilters)?.hideDuplicates) }}>
            <div>{(filtersState as InventoryFilters)?.hideDuplicates ? 'Show' : 'Hide'} Duplicates <i className={`fa-solid fa-eye${!(filtersState as InventoryFilters)?.hideDuplicates ? '-slash' : ''} h6 mb-0 pt-1`}></i></div>
        </Button>
    </>;

    const sellFilters = <>
        <Input id="searchFilters" defaultValue={(filtersState as InventoryFilters)?.searchFilters} customClass="searchFilters ms-3" placeholder="Search..." onChange={(str) => updateFilters('searchFilters', str)} />
        <MultiSelectSizes id="sizeFilter" selectedValue={(filtersState as InventoryFilters)?.sizeFilter} value={userData.sessionStorage!['allSizes'][userData!.settingsStorage!['sizeCountry']]} customClass='selectFilters ms-3' placeholder="Size" onChange={(str) => updateFilters('sizeFilter', str)} />
        <Button id="onlySellNow" customClass='selectFilters ms-3 pe-2 text-center' onClick={() => { updateFilters('onlySellNow', !(filtersState as InventoryFilters)?.onlySellNow) }}>
            <div>{!(filtersState as InventoryFilters)?.onlySellNow ? 'Only Sell Now' : 'All Sell'} <i className={`fa-solid fa-eye${!(filtersState as InventoryFilters)?.onlySellNow ? '-slash' : ''} h6 mb-0 pt-1`}></i></div>
        </Button>
    </>;


    const adminInventoryFilters = <>
        <Input id="searchFilters" defaultValue={(filtersState as InventoryFilters)?.searchFilters} customClass="searchFilters ms-3" placeholder="Search..." onChange={(str) => updateFilters('searchFilters', str)} />
        <MultiSelectSizes id="sizeFilter" selectedValue={(filtersState as InventoryFilters)?.sizeFilter} value={userData.sessionStorage!['allSizes'][userData!.settingsStorage!['sizeCountry']]} customClass='selectFilters ms-3' placeholder="Size" onChange={(str) => updateFilters('sizeFilter', str)} />
        <DatePickerComponent defaultValue={(filtersState as InventoryFilters)?.purDateFilter} id="purDateFilter" customClass="selectFilters ms-3" placeholder="Purchase Date" onChange={(str) => updateFilters('purDateFilter', str)} />
        <MultiSelect id="purStoreFilter" selectedValue={(filtersState as InventoryFilters)?.purStoreFilter} value={userData.sessionStorage!['retailers']} customClass='selectFilters ms-3' placeholder="Purchase Store" onChange={(str) => updateFilters('purStoreFilter', str)} />
    </>;

    const listedFilters = <>
        <Input id="searchFilters" defaultValue={(filtersState as ListedFilters)?.searchFilters} customClass="searchFilters ms-3" placeholder="Search..." onChange={(str) => updateFilters('searchFilters', str)} />
        <MultiSelectSizes id="sizeFilter" selectedValue={(filtersState as ListedFilters)?.sizeFilter} value={userData.sessionStorage!['allSizes'][userData!.settingsStorage!['sizeCountry']]} customClass='selectFilters ms-3' placeholder="Size" onChange={(str) => updateFilters('sizeFilter', str)} />
        <DatePickerComponent defaultValue={(filtersState as ListedFilters)?.purDateFilter} id="purDateFilter" customClass="selectFilters ms-3" placeholder="Purchase Date" onChange={(str) => updateFilters('purDateFilter', str)} />
        <MultiSelect id="purStoreFilter" selectedValue={(filtersState as ListedFilters)?.purStoreFilter} value={userData.sessionStorage!['retailers']} customClass='selectFilters ms-3' placeholder="Purchase Store" onChange={(str) => updateFilters('purStoreFilter', str)} />
    </>;

    const soldFilters = <>
        <Input id="searchFilters" defaultValue={(filtersState as SoldFilters)?.searchFilters} customClass="searchFilters ms-3" placeholder="Search..." onChange={(str) => updateFilters('searchFilters', str)} />
        <MultiSelectSizes id="sizeFilter" selectedValue={(filtersState as SoldFilters)?.sizeFilter} value={userData.sessionStorage!['allSizes'][userData!.settingsStorage!['sizeCountry']]} customClass='selectFilters ms-3' placeholder="Size" onChange={(str) => updateFilters('sizeFilter', str)} />
        <DatePickerComponent defaultValue={(filtersState as SoldFilters)?.purDateFilter} id="purDateFilter" customClass="selectFilters ms-3" placeholder="Purchase Date" onChange={(str) => updateFilters('purDateFilter', str)} />
        <MultiSelect id="purStoreFilter" selectedValue={(filtersState as SoldFilters)?.purStoreFilter} value={userData.sessionStorage!['retailers']} customClass='selectFilters ms-3' placeholder="Purchase Store" onChange={(str) => updateFilters('purStoreFilter', str)} />
        <DatePickerComponent defaultValue={(filtersState as SoldFilters)?.saleDateFilter} id="saleDateFilter" customClass="selectFilters ms-3" placeholder="Sale Date" onChange={(str) => updateFilters('saleDateFilter', str)} />
        <MultiSelect id="saleStoreFilter" selectedValue={(filtersState as SoldFilters)?.saleStoreFilter} value={userData.sessionStorage!['marketplaces']} customClass='selectFilters ms-3' placeholder="Sale Store" onChange={(str) => updateFilters('saleStoreFilter', str)} />
    </>;

    const exp_incFilters = <>
        <div className='add' onClick={() => props.openModal()}>
            <i className="fa-solid fa-plus"></i>
        </div>
        <Input id="searchFilters" defaultValue={(filtersState as ExpenseIncomeFilters)?.searchFilters} customClass="searchFilters ms-3" placeholder="Search..." onChange={(str) => updateFilters('searchFilters', str)} />
        <MultiSelect id="income_expenseServicesFilter" selectedValue={(filtersState as ExpenseIncomeFilters)?.income_expenseServicesFilter} value={userData.sessionStorage!['services']} customClass="selectFilters ms-3" placeholder="Services" onChange={(str) => updateFilters('income_expenseServicesFilter', str)} />
        <MultiSelect id="income_expenseFilter" selectedValue={(filtersState as ExpenseIncomeFilters)?.income_expenseFilter} value={["Income", "Expense"]} customClass="selectFilters ms-3" placeholder="Income/Expense" onChange={(str) => updateFilters('income_expenseFilter', str)} />
        <DatePickerComponent defaultValue={(filtersState as ExpenseIncomeFilters)?.dateFilter} id="dateFilter" customClass="selectFilters ms-3" placeholder="Date" onChange={(str) => updateFilters('dateFilter', str)} />
    </>;

    const shippingFilters = <>

        <Input id="searchFilters" defaultValue={(filtersState as ShippingFilters)?.searchFilters} customClass="searchFilters ms-3" placeholder="Search..." onChange={(str) => updateFilters('searchFilters', str)} />
        <DatePickerComponent defaultValue={(filtersState as ShippingFilters)?.saleDateFilter} id="saleDateFilter" customClass="selectFilters ms-3" placeholder="Sale Date" onChange={(str) => updateFilters('saleDateFilter', str)} />
        <MultiSelect id="saleStoreFilter" selectedValue={(filtersState as ShippingFilters)?.saleStoreFilter} value={userData.sessionStorage!['marketplaces']} customClass='selectFilters ms-3' placeholder="Sale Store" onChange={(str) => updateFilters('saleStoreFilter', str)} />
        <MultiSelect id="courierFilter" selectedValue={(filtersState as ShippingFilters)?.courierFilter} value={userData.sessionStorage!['couriers']} customClass="selectFilters ms-3" placeholder="Courier" onChange={(str) => updateFilters('courierFilter', str)} />
        <MultiSelect id="statusFilter" selectedValue={(filtersState as ShippingFilters)?.statusFilter} value={userData.sessionStorage!['shipStatus']} customClass="selectFilters ms-3" placeholder="Status" onChange={(str) => updateFilters('statusFilter', str)} />
        <Button id="refreshTracking" customClass='selectFilters ms-3 pe-2 text-center' onClick={() => { updateFilters('refresh', 'true') }}>
            <div><i className="fa-solid fa-refresh"></i></div>
        </Button>
    </>;

    return (
        <div className='filters'>
            <div className='d-flex innerFilters'>
                {props.type === 'inventory' && inventoryFilters}
                {props.type === 'wtbs' && wtbsFilters}
                {props.type === 'sell' && sellFilters}
                {props.type === 'adminInventory' && adminInventoryFilters}
                {props.type === 'listed' && listedFilters}
                {props.type === 'sold' && soldFilters}
                {props.type === 'incomes-expenses' && exp_incFilters}
                {props.type === 'shippings' && shippingFilters}
                <Button id="clearFilters" customClass='selectFilters ms-3 pe-2 text-center' onClick={() => { clearFilters() }}>
                    <div>Clear <i className="fa-solid fa-eraser"></i></div>
                </Button>
                {
                    props.showDeleteSelectedTasks && props.deleteSelectedTasks && <Button id="clearFilters" customClass='selectFilters ms-3 pe-2 text-center' onClick={() => { props.deleteSelectedTasks!() }}>
                        <div>Delete Selected <i className="fa-solid fa-trash"></i></div>
                    </Button>
                }
            </div>

            <CustomModal show={showCustomModal} onHide={() => setShowCustomModal(false)}>
                {showCustomModalChildren}
            </CustomModal>

        </div>
    );
}

export default Filters;
