import {DashboardInterface} from "../../../../../services/DashboardService";
import {formatNumber} from "../../../../../utility/commonUtils";

export interface CardsInterface {
    real_time: Array<CardInterface>;
    all_time: Array<CardInterface>;
}

export interface CardInterface {
    name: string;
    fa_icon: string;
    value: any;
    format: boolean;
}

export default class CardShower {

    cards: CardsInterface = {
        real_time: [
            {
                name: 'inventoryValue',
                fa_icon: 'fa-solid fa-sack-dollar',
                value: 'N/A',
                format: true
            },
            {
                name: 'retailPrice',
                fa_icon: 'fa-regular fa-credit-card',
                value: 'N/A',
                format: true
            },
            {
                name: 'inventoryItems',
                fa_icon: 'fa-solid fa-clipboard-list',
                value: 'N/A',
                format: false
            },
            {
                name: 'estimatedProfit',
                fa_icon: 'fa-solid fa-euro-sign',
                value: 'N/A',
                format: true
            },
        ],
        all_time: [
            {
                name: '_payoutsAmount',
                fa_icon: 'fa-solid fa-sack-dollar',
                value: 'N/A',
                format: true
            },
            {
                name: '_retailPrice',
                fa_icon: 'fa-regular fa-credit-card',
                value: 'N/A',
                format: true
            },
            {
                name: '_otherExpenses',
                fa_icon: 'fa-solid fa-cart-shopping',
                value: 'N/A',
                format: true
            },
            {
                name: '_otherIncome',
                fa_icon: 'fa-regular fa-chart-line',
                value: 'N/A',
                format: true
            },
            {
                name: '_soldPairs',
                fa_icon: 'fa-solid fa-truck-arrow-right',
                value: 'N/A',
                format: false
            },
            {
                name: '_purchasedPairs',
                fa_icon: 'fa-solid fa-basket-shopping',
                value: 'N/A',
                format: false
            },
            {
                name: '_balance',
                fa_icon: 'fa-solid fa-scale-balanced',
                value: 'N/A',
                format: true
            },
            {
                name: '_estimatedProfit',
                fa_icon: 'fa-solid fa-euro-sign',
                value: 'N/A',
                format: true
            },
            {
                name: '_netProfit',
                fa_icon: 'fa-solid fa-euro-sign',
                value: 'N/A',
                format: true
            }
        ]
    };


    public getCards(dashInfo: Array<{name: string, value: any}>): CardsInterface {
        this.cards.real_time.forEach(e=>{
            e.value = dashInfo.find(eD=>eD.name === e.name)?.value ?? 0;
            e.value = e.format ? formatNumber(e.value) : e.value;
        })
        this.cards.all_time.forEach(e=>{
            e.value = dashInfo.find(eD=>eD.name === e.name)?.value ?? 0;
            e.value = e.format ? formatNumber(e.value) : e.value;
        })
        return this.cards;
    }

}