import React, { useEffect, useState } from "react";
import './rowItem.scss';
import FiltersUtils, { FiltersInterface } from "../../../utility/filtersUtils";
import ViewOrEditItemModal from "../../modals/ViewOrEditItemModal/ViewOrEditItemModal";
import { InventoryResultInterface, Size } from "../../../services/InventoryInterfaces";
import { formatPrice, getDateByTimeStamp, isAdmin, makeUpperCase } from "../../../utility/commonUtils";
import LazyImage from "../lazyimage/LazyImage";
import SaleItemModal from "../../modals/SaleItemModal/SaleItemModal";
import UserDataStorage from "../../../singletonStorage/userDataStorage";
import WarningModal from "../../modals/WarningModal/WarningModal";
import ToastHandler from "../../../utility/ToastHandler/ToastHandler";
import { ExpenseIncome } from "../../../services/expenseincome/ExpenseIncomeInterface";
import AddIncomesExpensesModal from "../../modals/Add_View_IncomesExpensesModal/AddIncomesExpensesModal";
import Checkbox from "../checkbox/Checkbox";
import { useWindowSize } from "../../../utility/customHooks";
import CustomModal from "../../modals/CustomModal/CustomModal";
import { jwtDecode } from "jwt-decode";
import Loader from "../loader/Loader";
import { roleCheck } from "../../../utility/roleChecker";
import Button from "../button/Button";
import Logo from "../../../assets/images/logo/transparent-logo.png";


interface RowItemProps {
  filters?: Array<FiltersInterface>,
  item: InventoryResultInterface | ExpenseIncome,
  type: string,
  delete?: (params?: Record<string, any>) => void,
  refresh: () => void,
  selected?: boolean,
  onSelect?: () => void,
  onAction?: (action: string) => void,
  hideInfo?: boolean
}

const RowItem: React.FC<RowItemProps> = (props) => {

  // Per Aprire la Modale di Aggiunta
  const [showModal, setShowModal] = useState<boolean>(false);
  // Per Aprire la Modale di Vendita (Sale Item)
  const [showSaleModal, setShowSaleModal] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<{ show: boolean, message: string }>({ show: false, message: '' });

  // Parametri checkbox per le varie modali di avviso
  const [alsoStandard, setAlsoStandard] = useState<boolean>(false);

  const userData = UserDataStorage.getInstance();

  const [width] = useWindowSize();

  // Parametri modale custom
  const urlDiscordPlan = 'https://whop.com/hub/stepup/discord-T0pjKb6C57TZ8D/app/';
  const [isLoadingInApp, setIsLoading] = useState<boolean>(false);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [showCustomModalChildren, setShowCustomModalChildren] = useState();

  const closeModal = (str: string, didChange?: boolean) => {
    switch (str) {
      case "sale":
        setShowSaleModal(false);
        break;
      case "warning":
        setWarningModal({ show: false, message: "" });
        setAlsoStandard(false);
        if (didChange) {
          if (isInventory(props.item)) {
            props.delete && props.delete();
          } else {
            props.delete && props.delete({ alsoStandard: alsoStandard });
          }
        }
        break;
      case "":
        setShowModal(false);
        break;
      default:
        break;
    }
    if (didChange) {
      setTimeout(() => {
        props.refresh();
      }, 250);
    }
  }

  const getValueForInventory = (name: string): string => {
    try {
      let item = props.item as InventoryResultInterface;

      switch (name) {
        case "product_name":
          return item?.item.name;
        case "size":
          let sizeCountry = userData.settingsStorage!['sizeCountry'];
          return item.variant.size[sizeCountry as keyof Size] + " " + sizeCountry;
        case "sku":
          return item.item.sku;
        case "price":
          return formatPrice(item.price.value, item.price.currency, true);
        case "buy_date":
          return getDateByTimeStamp(item.purchaseDate);
        case "buy_store":
          return item.retailerId;
        case "possible_payout":
          return "-";
        case "profit":
          let profitValue = formatPrice(item.sale.profit.value, item.sale.profit.currency, true);
          let profitRoi = !item?.sale?.roi ? "" : ("(" + item?.sale?.roi + "%)");
          return profitValue + " " + profitRoi;
        case "listing_status":
          return item?.status || "-";
        case "sale_price":
          return formatPrice(item.sale.price.value, item.sale.price.currency);
        case "sale_date":
          return item?.sale?.saleDate ? getDateByTimeStamp(item?.sale.saleDate) : "-";
        case "sale_store":
          return item?.sale?.store || "-";
        case "courier":
          return item?.sale?.ship?.courierId || "-";
        case "tracking":
          return item?.sale?.ship?.trakingCode || "-";
        case "status":
          return item?.sale?.ship?.status || "-";
        case "label":
          return item?.sale?.ship?.labelUrl || "-";
        case "owner":
          return item?.ownerDiscordId || "-";
        case "ownerEmail":
          return item?.ownerEmail || "-";
        case "paymethods":
          return item?.paymethods ? item?.paymethods.join(" - ") : "-";
        case "country":
          return item?.ownerCountry || "-";
        default:
          return "-";
      }
    } catch (err) {
      return "500 Err";
    }
  }

  const getValueForIncomeExpense = (name: string): string => {
    try {
      let item = props.item as ExpenseIncome;
      switch (name) {
        case "service":
          return item?.service;
        case "income_expense":
          return item?.type;
        case "spent_earned":
          return formatPrice(item.price.value, item.price.currency, true);
        case "date":
          return item?.date ? getDateByTimeStamp(item?.date) : "-";
        case "price":
          return formatPrice(item.price.value, item.price.currency);
        case "begin_date":
          return item?.startDate ? getDateByTimeStamp(item?.startDate) : "-";
        case "frequency":
          return item?.recurrentTime ? "Every " + item.recurrentTime + " days" : "-";
        case "description":
          return item?.description;
        default:
          return "-";
      }
    } catch (err) {
      return "500 Err";
    }
  }

  const openTrackingLink = () => {
    if ((props?.item as InventoryResultInterface)?.sale?.ship?.trackingUrl) {
      window.open((props?.item as InventoryResultInterface)?.sale?.ship?.trackingUrl);
    } else {
      ToastHandler.customToast({
        message: 'Cannot open url, retry later or go to the courier website'
      })
    }
  }

  function isInventory(object: any): object is InventoryResultInterface {
    return 'item' in object;
  }

  function isNot(names: Array<string>) {
    return !names.includes(props.type);
  }

  const planClaim = async (role: string) => {
    setIsLoading(true)
    return await userData.refreshRoles().then(e => {
      const jwt: any = jwtDecode(e?.jwt);
      if (jwt && jwt.roles && jwt.roles.includes(role)) {
        userData.getUser().then(async () => {
          userData.getUserSession().then(() => {
            setTimeout(() => {
              setShowCustomModal(false);
              setShowCustomModalChildren(<></> as any);
              setIsLoading(false);
            }, 200);
          })
        }).catch((err: any) => {
          if (err?.response?.data && err?.response?.data?.errorMessage === "Invalid AuthToken") {
              localStorage.clear();
              setIsLoading(false);
              ToastHandler.customToast({ message: "Invalid login, please logout and retry" })
              return;
          }
          if (err?.response?.data && err?.response?.data?.errorCode === 418) {
              setIsLoading(false);
              ToastHandler.customToast({ message: "It looks like you don't have permission, you need a plan in order to use StepUp" })
              return
          }
          ToastHandler.customToast({ message: "Error during login. Retry later" })
      });
      } else {
        setIsLoading(false);
        ToastHandler.customToast({ message: "It looks like you don't have permission, you need to connect to Discord in order to use this feature" })
      }
    }).catch(e => {
      setIsLoading(false);
      ToastHandler.customToast({ message: "It looks like you don't have permission, you need to connect to Discord in order to use this feature" })
    })
  }

  const checkRole = (role: string) => {

    const available = roleCheck(role);
    if (!available) {
      switch (role) {
        case 'DiscordClaimed':
          {
            const children: any = <>
              <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white'>
                <div className='loginPrompt'>
                  {!isLoadingInApp &&
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      <img src={Logo} width={60} height={60} alt='StepUp Logo' />
                      <strong className='ms-2'>{process.env.REACT_APP_NAME}</strong>
                    </div>
                  }
                  <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                    {isLoadingInApp && <Loader />}
                    {isLoadingInApp && <span className='my-2 welcomeLoading'>Welcome, StepUp is loading...</span>}
                    {!isLoadingInApp && <span className='my-2 text-center p-2'>
                      To use this feature, you need your account connected to our Discord server.
                      You can do that <a className='fw-bold' href={urlDiscordPlan} rel="noreferrer" target='_blank'>here</a>,
                      then return to this page and press the button below
                    </span>}
                    {!isLoadingInApp && <div className='d-flex align-items-center'>
                      <Button id='discordClaim' value={"Discord claimed"} customClass='mx-1' onClick={() => planClaim(role)} />
                      <Button id='close' value={"Close"} customClass='mx-1' onClick={() => setShowCustomModal(false)} />
                    </div>}
                  </div>
                </div>
              </div>
            </>
            setShowCustomModalChildren(children)
            setShowCustomModal(true);
            break;
          }
        default: break;
      }
    }
    return available;

  }


  return (
    <>
      <div className="rowItemWrapper position-relative" style={props.selected ? { backgroundColor: '#5f6f87' } : {}}>
        <div className="rowItem">
          <div>
            {props.onSelect && props.type !== 'adminInventory' ? <Checkbox customClass="checkboxItem me-2 cursor-pointer" id="inventoryCheckbox"
              selected={props.selected!} onClick={() => {
                props.onSelect!();
              }} /> : <div style={{ width: 8 }} />}
          </div>
          {/* <img className="image" src={props.item.item.imgUrl} alt="Item"/> */}
          {
            isInventory(props.item) && <LazyImage customClass="image" src={props?.item?.item?.imgUrl} alt="Item" />
          }
          {
            !isInventory(props.item) && <div style={{ width: width > 768 ? 56 : 0, height: 40 }} />
          }
          {width > 768 && <div className="w-100 ms-2 pl-2">
            <div className="row gx-0 d-flex align-items-center justify-content-between">
              {isInventory(props.item) && props.filters?.map((v, i) => {
                let value = getValueForInventory(v.name);
                return (<div key={v.name + i} className={"col-" + v.col + " p-0"}>
                  {v.name === "profit" &&
                    <span className={props.hideInfo ? 'blurred' : (value !== "- " && value.length > 1) ? value.includes("-") ? 'itemRed' : 'itemGreen' : ''}>
                      {value}
                    </span>
                  }
                  {v.name === "tracking" &&
                    <span className={props.hideInfo ? 'blurred' : 'text-decoration-underline cursor-pointer'} onClick={() => openTrackingLink()}>
                      {value}
                    </span>
                  }
                  {v.name === "status" &&
                    <span className={!value.includes("-") ? (value.toLowerCase() === "delivered" || value.toLowerCase() === "not found") ? value.toLowerCase() === "delivered" ? 'itemGreen' : 'itemRed' : 'itemYellow' : ''}>
                      {value}
                    </span>
                  }
                  {(v.name.includes('price') || v.name.includes('store') || v.name.includes('date')) &&
                    <span className={props.hideInfo ? 'blurred' : ''}>
                      {value}
                    </span>
                  }
                  {v.name === "country" && <span className="d-flex align-items-center">
                    <span className={"me-1 flagIcon fi fi-" + value.split("/")[0].toLowerCase()}></span>
                    {value.split("/")[0]}
                  </span>}
                  {v.name !== "profit" && v.name !== 'tracking' && v.name !== 'status' && v.name !== 'country' && !v.name.includes('price') && !v.name.includes('store') && !v.name.includes('date') &&
                    <span>
                      {value}
                    </span>
                  }
                </div>);
                //     return (<div key={v.name + i} className={"col-" + v.col + " p-0"}>
                //         <div className="listingWrapper">
                //             {["StockX", "Restocks", "Alias", "Klekt"].map((e)=><div key={e} className={"listing " + e}>{e}</div>)}
                //         </div>
                //     </div>);
              })}
              {
                !isInventory(props.item) && props.filters?.map((v, i) => {
                  let value = getValueForIncomeExpense(v.name);
                  return (<div key={v.name + i} className={"col-" + v.col + " p-0"}>
                    {(v.name === "spent_earned" || v.name === "price") &&
                      <span className={props.hideInfo ? 'blurred' : (props.item as ExpenseIncome).type === "Expense" ? 'itemRed' : 'itemGreen'}>
                        {value}
                      </span>
                    }
                    {!(v.name === "spent_earned" || v.name === "price") &&
                      <span>
                        {value}
                      </span>
                    }
                  </div>);
                })
              }
              {<div className={"options"}>
                {isNot(['adminInventory', 'sell', 'sale', 'shipping', "wtb"]) && isInventory(props.item) && <div className={"btn btn-success text-white"} onClick={() => setShowSaleModal(true)}>
                  <i className="fa-solid fa-tag"></i>
                </div>}
                {isNot(['sell', "wtb"]) && <div className={"btn btn-info text-white"} onClick={() => setShowModal(true)}>
                  <i className="fa-solid fa-eye"></i>
                </div>}
                {isNot(['adminInventory', 'sell']) && <div className={"btn btn-danger text-white"} onClick={() => props.delete && setWarningModal({ show: true, message: 'Are you sure you want to delete this item?' })}>
                  <i className="fa-solid fa-trash-can"></i>
                </div>}
                {!isNot(['sell']) && props.onAction !== undefined && <div className={"btn btn-success text-white"} onClick={() => checkRole('DiscordClaimed') && props.onAction!('sell')}>
                  <span className="fw-bold" style={{ fontSize: 12 }}>Sell</span>
                </div>}
              </div>}
            </div>
          </div>
          }
          {width < 768 && <div className="w-100 ms-2 pl-2">
            <div className="row gx-0 rowInfoContainer">
              {isInventory(props.item) && props.filters?.filter((v) => getValueForInventory(v.name) !== "-").map((v, i) => {
                let value = getValueForInventory(v.name);
                return (<div key={v.name + i} className={props.filters?.filter((v) => getValueForInventory(v.name) !== "-")[i + 1] ? "" : "mb-1"}>
                  {v.name === "profit" &&
                    <>
                      <span className={'fw-bold pe-1'}>
                        {makeUpperCase(v.label)}:
                      </span>
                      <span className={props.hideInfo ? 'blurred' : (value !== "- " && value.length > 1) ? value.includes("-") ? 'itemRed' : 'itemGreen' : ''}>
                        {value}
                      </span>
                    </>
                  }
                  {v.name === "tracking" &&
                    <>
                      <span className={'fw-bold pe-1'}>
                        {makeUpperCase(v.label)}:
                      </span>
                      <span className={props.hideInfo ? 'blurred' : 'text-decoration-underline cursor-pointer'} onClick={() => openTrackingLink()}>
                        {value}
                      </span>
                    </>
                  }
                  {v.name === "status" &&
                    <>
                      <span className={'fw-bold pe-1'}>
                        {makeUpperCase(v.label)}:
                      </span>
                      <span className={!value.includes("-") ? (value.toLowerCase() === "delivered" || value.toLowerCase() === "not found") ? value.toLowerCase() === "delivered" ? 'itemGreen' : 'itemRed' : 'itemYellow' : ''}>
                        {value}
                      </span>
                    </>
                  }
                  {(v.name.includes('price')) &&
                    <>
                      <span className={'fw-bold pe-1'}>
                        {makeUpperCase(v.label)}:
                      </span>
                      <span className={props.hideInfo ? 'blurred' : ''}>
                        {value}
                      </span>
                    </>
                  }
                  {v.name === "product_name" &&
                    <>
                      <span className={'fw-bold mt-1 titleProduct'}>
                        {value}
                      </span>
                    </>
                  }
                  {v.name === "sku" &&
                    <>
                      <span className={'fw-bold pe-1'}>
                        {makeUpperCase(v.label)}:
                      </span>
                      <span>
                        {value}
                      </span>
                    </>
                  }
                  {v.name === "size" &&
                    <>
                      <span className={'fw-bold pe-1'}>
                        {makeUpperCase(v.label)}:
                      </span>
                      <span>
                        {value}
                      </span>
                    </>
                  }
                  {v.name === "country" &&
                    <>
                      <span className={'fw-bold pe-1'}>
                        {makeUpperCase(v.label)}:
                      </span>
                      <span>
                        <span className={"me-1 flagIcon fi fi-" + value.split("/")[0].toLowerCase()}></span>
                        {value.split("/")[0]}
                      </span>
                    </>
                  }
                  {v.name === "paymethods" &&
                    <>
                      <span className={'fw-bold pe-1'}>
                        {makeUpperCase(v.label)}:
                      </span>
                      <span>
                        {value}
                      </span>
                    </>
                  }
                </div>);
              })}
              {
                !isInventory(props.item) && props.filters?.map((v, i) => {
                  let value = getValueForIncomeExpense(v.name);
                  return (<div key={v.name + i} className={props.filters![i + 1] ? "" : "mb-1"}>
                    {(v.name === "spent_earned" || v.name === "price") &&
                      <>
                        <span className={'fw-bold pe-1'}>
                          {makeUpperCase(v.label)}:
                        </span>
                        <span className={props.hideInfo ? 'blurred' : (props.item as ExpenseIncome).type === "Expense" ? 'itemRed' : 'itemGreen'}>
                          {value}
                        </span>
                      </>
                    }
                    {!(v.name === "spent_earned" || v.name === "price") &&
                      <>
                        <span className={'fw-bold pe-1'}>
                          {makeUpperCase(v.label)}:
                        </span>
                        <span>
                          {value}
                        </span>
                      </>
                    }
                  </div>);
                })
              }
              {<div className={"options"}>
                {isNot(['adminInventory', 'sell', 'sale', 'shipping', "wtb"]) && isInventory(props.item) && <div className={"btn btn-success text-white"} onClick={() => setShowSaleModal(true)}>
                  <i className="fa-solid fa-tag"></i>
                </div>}
                {isNot(['sell', "wtb"]) && <div className={"btn btn-info text-white"} onClick={() => setShowModal(true)}>
                  <i className="fa-solid fa-eye"></i>
                </div>}
                {isNot(['adminInventory', 'sell']) && <div className={"btn btn-danger text-white"} onClick={() => props.delete && setWarningModal({ show: true, message: 'Are you sure you want to delete this item?' })}>
                  <i className="fa-solid fa-trash-can"></i>
                </div>}
                {!isNot(['sell']) && props.onAction !== undefined && <div className={"btn btn-success text-white"} onClick={() => checkRole('DiscordClaimed') && props.onAction!('sell')}>
                  <span className="fw-bold" style={{ fontSize: 14, padding: '0px 4px' }}>Sell</span>
                </div>}
              </div>}
            </div>
          </div>
          }
        </div>
      </div >

      {isInventory(props.item) &&
        <>
          <SaleItemModal show={showSaleModal} onHide={(didChange?: boolean) => closeModal('sale', didChange)} item={props.item} />
          <ViewOrEditItemModal show={showModal} disableEdit={props.type === 'adminInventory'} onHide={(didChange?: boolean) => { closeModal('', didChange) }} type={props.type} item={props.item} />
        </>
      }

      {
        !isInventory(props.item) &&
        <>
          <AddIncomesExpensesModal show={showModal} onHide={(didChange?: boolean) => { closeModal('', didChange) }} item={props.item} recurrent={!!props.item.recurrentTime} update onSubmit={() => closeModal('', true)} />
        </>
      }

      {
        <WarningModal show={warningModal.show} onHide={(didChange?: boolean) => { closeModal('warning', didChange) }}>
          <div className="text-center">
            <div>{warningModal.message}</div>
            {isInventory(props.item) &&
              <div className="d-flex align-items-center justify-content-start mt-2 w-100">
                <LazyImage customClass="image" src={props?.item?.item?.imgUrl} alt="Item" />
                <div className="ps-1 w-100 d-flex flex-column align-items-center justify-content-center" style={{ fontWeight: 600 }}>
                  <div>{props.item?.item?.name}</div>
                  <div>{props.item?.item?.sku}</div>
                </div>
              </div>
            }
            {!isInventory(props.item) && props.item.recurrentTime &&
              <div className="d-flex align-items-center justify-content-start mt-2 p-2 w-100">
                <Checkbox id="setAlsoStandard" customClass={"cursor-pointer"} selected={alsoStandard} onClick={() => setAlsoStandard(!alsoStandard)} />
                <span className="ms-2 text-white cursor-pointer" onClick={() => setAlsoStandard(!alsoStandard)} style={{ fontSize: 14 }}>Also delete Standards Services linked to this</span>
              </div>
            }
          </div>
        </WarningModal>
      }


      {
        <CustomModal show={showCustomModal} onHide={() => setShowCustomModal(false)}>
          {showCustomModalChildren}
        </CustomModal>
      }
    </>
  );
}


export default RowItem;