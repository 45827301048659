import React, { useEffect, useState } from 'react';
import './App.scss';
import './styling.scss';
import Home from './components/home/Home';
import ThemeHandler from './utility/dynamicTheme';
import Loader from "./components/common/loader/Loader";
import { addAccessTokenInterceptor, logout } from "./utility/httpClient";
import UserDataStorage from "./singletonStorage/userDataStorage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastHandler from "./utility/ToastHandler/ToastHandler";
import Logo from "./assets/images/logo/transparent-logo.png";
import Button from './components/common/button/Button';
import { requestForToken } from './singletonStorage/firebase';

import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';


function App() {

    const location = useLocation();

    const userData = UserDataStorage.getInstance();

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [isLoadingInApp, setIsLoading] = useState<boolean>(false);

    const [message, setMessage] = useState("");

    const urlPlan = "https://whop.com/stepup";

    const getAccessToken = async (code: string) => {
        return await userData.getAccessToken(code)
    }

    const loginWithRedirect = () => {
        const origin = window.location.origin
        const urlRedirect = `https://whop.com/oauth?client_id=jRF3--7dUCgzlYXV438AG5Sp5ZhnGh3773KjR3Gyn5Y&redirect_uri=${origin}/oauth/login`;
        window.location.href = urlRedirect;
    }

    const planClaim = async () => {
        setIsLoading(true)
        return await userData.refreshRoles().then(e => {
            const jwt: any = jwtDecode(e?.jwt);
            if (jwt && jwt.roles && (jwt.roles.includes('FreePlan') || jwt.roles.includes('Renewal'))) {
                userData.getUser().then(async () => {
                    userData.getUserSession().then(() => {
                        setTimeout(() => {
                            Notification();
                            setIsAuthenticated(true);
                            setIsLoading(false);
                        }, 200);
                    })
                }).catch((err: any) => {
                    if (err?.response?.data && err?.response?.data?.errorMessage === "Invalid AuthToken") {
                        localStorage.clear();
                        setIsAuthenticated(false);
                        setIsLoading(false);
                        ToastHandler.customToast({ message: "Invalid login, please logout and retry" })
                        return;
                    }
                    if (err?.response?.data && err?.response?.data?.errorCode === 418) {
                        setIsAuthenticated(false);
                        setMessage("needplan");
                        setIsLoading(false);
                        ToastHandler.customToast({ message: "It looks like you don't have permission, you need a plan in order to use StepUp" })
                        return
                    }
                    ToastHandler.customToast({ message: "Error during login. Retry later" })
                });
            } else {
                setIsAuthenticated(false);
                setMessage("needplan");
                setIsLoading(false);
                ToastHandler.customToast({ message: "It looks like you don't have permission, you need a plan in order to use StepUp" })
            }
        }).catch(e => {
            setIsAuthenticated(false);
            setMessage("needplan");
            setIsLoading(false);
            ToastHandler.customToast({ message: "It looks like you don't have permission, you need a plan in order to use StepUp" })
        })
    }

    const Notification = () => {
        requestForToken().then(async (e) => {
            if (!e) {
                return ToastHandler.customToast({ message: 'Error while activating Push Notifications' })
            }
            userData.sessionFireBaseToken = e as string;
            await userData.firebaseTokenSubscription(e as string);
        });
    }

    useEffect(() => {
        let link: any = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        process.env.REACT_APP_ENV !== "prod" ? (link.href = '/faviconDev.ico') : (link.href = '/favicon.ico');

        let linkApple: any = document.querySelector("link[rel~='apple-touch-icon']");
        if (!linkApple) {
            linkApple = document.createElement('link');
            linkApple.rel = 'apple-touch-icon';
            document.getElementsByTagName('head')[0].appendChild(linkApple);
        }
        process.env.REACT_APP_ENV !== "prod" ? (linkApple.href = '/logo192Dev.png') : (linkApple.href = '/logo192.png');

        let iOS = navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);
        if (iOS)
            (document.head.querySelector('meta[name="viewport"]') as any).content = "width=device-width, initial-scale=1, maximum-scale=1";
        else
            (document.head.querySelector('meta[name="viewport"]') as any).content = "width=device-width, initial-scale=1";


        const jwt = localStorage.getItem('UserJWT');
        if (jwt && jwt !== "") {
            setIsLoading(true);
            addAccessTokenInterceptor();
            userData.getUser().then(async (e) => {
                userData.getUserSession().then(() => {
                    process.env.REACT_APP_ENV !== 'prod' && console.log(userData);
                    setTimeout(() => {
                        Notification();
                        setIsAuthenticated(true);
                        setIsLoading(false);
                    }, 200);
                });
            }).catch((err: any) => {
                if (err?.response?.data && err?.response?.data?.errorMessage === "Invalid AuthToken") {
                    localStorage.clear();
                    setIsAuthenticated(false);
                    setIsLoading(false);
                    ToastHandler.customToast({ message: "Invalid login, please logout and retry" })
                    return;
                }
                if (err?.response?.data && err?.response?.data?.errorCode === 418) {
                    setIsAuthenticated(false);
                    setMessage("needplan");
                    setIsLoading(false);
                    ToastHandler.customToast({ message: "It looks like you don't have permission, you need a plan in order to use StepUp" })
                    return
                }
                ToastHandler.customToast({ message: "Error during login. Retry later" })
            });
        } else {
            const code = new URLSearchParams(location.search).get("code");
            if (code) {
                setIsLoading(true);
                getAccessToken(code).then(e => {
                    userData.getUser().then(async () => {
                        userData.getUserSession().then(() => {
                            process.env.REACT_APP_ENV !== 'prod' && console.log(userData);
                            setTimeout(() => {
                                Notification();
                                setIsAuthenticated(true);
                                setIsLoading(false);
                            }, 200);
                        });
                    }).catch((err: any) => {
                        if (err?.response?.data && err?.response?.data?.errorMessage === "Invalid AuthToken") {
                            localStorage.clear();
                            setIsAuthenticated(false);
                            setIsLoading(false);
                            ToastHandler.customToast({ message: "Invalid login, please logout and retry" })
                            return;
                        }
                        if (err?.response?.data && err?.response?.data?.errorCode === 418) {
                            setIsAuthenticated(false);
                            setMessage("needplan");
                            setIsLoading(false);
                            ToastHandler.customToast({ message: "It looks like you don't have permission, you need a plan in order to use StepUp" })
                            return
                        }
                        ToastHandler.customToast({ message: "Error during login. Retry later" })
                    });
                });
            } else {
                setIsAuthenticated(false);
                setIsLoading(false);
            }
        }
    }, [userData]);

    // Inizializza classi globali
    ThemeHandler.getInstance().setTheme('dark');

    return (
        <div className="App">
            {(isLoadingInApp || !isAuthenticated) ?
                <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white'>
                    <div className='loginPrompt'>
                        {!isLoadingInApp &&
                            <div className="w-100 d-flex justify-content-center align-items-center">
                                <img src={Logo} width={60} height={60} alt='StepUp Logo' />
                                <strong className='ms-2'>{process.env.REACT_APP_NAME}</strong>
                            </div>
                        }
                        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                            {isLoadingInApp && <Loader />}
                            {isLoadingInApp && <span className='my-2 welcomeLoading'>Welcome, StepUp is loading...</span>}
                            {isLoadingInApp && <Button id='logout' value={"Logout"} onClick={() => logout()} />}
                            {!isLoadingInApp && message === "needplan" && <span className='my-2 text-center p-2'>
                                To use StepUp, you need a plan.
                                Please claim a plan <a className='fw-bold' href={urlPlan} rel="noreferrer" target='_blank'>here</a>,
                                then return to this page and press the button below
                            </span>}
                            {!isLoadingInApp && message !== "" && <div className='d-flex align-items-center'>
                                <Button id='planClaim' value={"Plan claimed"} customClass='mx-1' onClick={() => planClaim()} />
                                <Button id='logout' value={"Logout"} customClass='mx-1' onClick={() => logout()} />
                            </div>}
                            {!isLoadingInApp && !isAuthenticated && message === "" && <Button id='login' customClass='my-2' value={"Sign In"} onClick={() => loginWithRedirect()} />}
                        </div>
                    </div>
                </div> :
                <Home />
            }
            <ToastContainer />
        </div>
    );
}

export default App;
