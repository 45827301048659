import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//import ThemeHandler from '../../../utility/dynamicTheme';
import SideBarNavigator from '../../main/sidebar/utils/sidebar-navigator';
import './Header.scss';
import ToastHandler from "../../../utility/ToastHandler/ToastHandler";
import { motion } from "framer-motion";
import { requestForToken } from './../../../singletonStorage/firebase';
import UserDataStorage from '../../../singletonStorage/userDataStorage';
import { jwtDecode } from 'jwt-decode';
import { logout } from '../../../utility/httpClient';

function Header(props: any) {

    const userData = UserDataStorage.getInstance();
    const { pathname } = useLocation();
    const sideBarNavigator = new SideBarNavigator();
    const user: any = jwtDecode(localStorage.getItem('UserJWT')!);

    //const [isDarkMode, setDarkMode] = useState(true);

    const Notification = () => {
        requestForToken().then(async (e) => {
            if (!e) {
                return ToastHandler.customToast({ message: 'Error while activating Push Notifications' })
            }
            userData.sessionFireBaseToken = e as string;
            await userData.firebaseTokenSubscription(e as string);
        });
    }

    useEffect(() => {
        // if (ThemeHandler.getTheme() === 'dark') {
        //     setDarkMode(true);
        // } else {
        //     setDarkMode(false);
        // }
    }, [])


    // const toggleDarkMode = () => {
    //     const isDark = isDarkMode;
    //     setDarkMode(!isDarkMode);
    //     ThemeHandler.getInstance().setTheme(isDark ? 'light' : 'dark');
    // }

    return (
        <motion.main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15 }}
        >
            <div className='header'>
                <div className='h4 color-white'>{sideBarNavigator.getNameByUrl(pathname)}</div>
                <div className='d-flex justify-content-center align-items-center'>
                    {/*<div className='themeButton' onClick={toggleDarkMode}>*/}
                    {/*    {isDarkMode ? <i className="fas fa-sun" style={{color: 'rgb(200, 200, 200)'}}></i> : <i className="fas fa-moon" style={{color: 'rgb(80, 80, 80)'}}></i>}*/}
                    {/*</div>*/}
                    {props?.show?.notification && <div className='notificationButton cursor-pointer' onClick={() => Notification()}>
                        <i className="fa-solid fa-bell"></i>
                    </div>}
                    {user.pic ?
                        <img className='profileButton' referrerPolicy="no-referrer" src={user.pic} alt={"Profile"} onClick={() => console.log(localStorage.getItem('UserJWT')!)} />
                        : <div className='profileButton' onClick={() => console.log(localStorage.getItem('UserJWT')!)}><i className="fa-solid fa-user"></i>                </div>}
                    <div className='logoutButton' onClick={() => logout()}>
                        <i className="fa-solid fa-right-from-bracket"></i>
                    </div>
                </div>
            </div>
        </motion.main>
    );
}

export default Header;
