import React, { useRef } from 'react';
import './DatePicker.scss';
import DatePicker, { DateObject } from "react-multi-date-picker";
import moment from "moment/moment";

interface DatePickerProps {
    id: string;
    onChange: (arg: Array<any>) => void;
    disabled?: boolean;
    customClass?: string;
    placeholder?: string;
    defaultValue?: any;
}

const DatePickerComponent: React.FC<DatePickerProps> = (props) => {

    const datePickerRef = useRef<any>(null);

    const handleOpenCalendar = () => {
        if (datePickerRef.current) {
            datePickerRef.current.openCalendar();
        }
    };

    //const [values, setValues] = useState<DateObject | DateObject[] | null>([]);

    const onDatePickerChange = (selectedDates: DateObject | DateObject[] | null) => {
        if (selectedDates != null) {
            let timestampDates = (selectedDates as DateObject[]).map(e => {
                let date = e.format('DD-MM-YYYY');
                return moment.utc(date, 'DD-MM-YYYY', true).valueOf();
            });
            let strSelectedDates: string = selectedDates.toString();
            if (strSelectedDates.includes(',')) {
                strSelectedDates = strSelectedDates.replace(',', '-');
            }
            //setValues(selectedDates);
            props.onChange(timestampDates);
        }
    }

    return (
        <>
            <div className='position-relative cursor-pointer'>
                <DatePicker
                    ref={datePickerRef}
                    inputClass={`datePickerInput ${props.customClass || ''}`}
                    className='datePickerCalendar'
                    arrowClassName='bg-dark'
                    range
                    fixMainPosition
                    placeholder={`${props.placeholder || ""}`}
                    value={props.defaultValue}
                    onChange={(selectedDates) => onDatePickerChange(selectedDates)}
                />
                <div className='icon-date-picker' onClick={handleOpenCalendar}>
                    <i className="fa-regular fa-calendar"></i>
                </div>
            </div>
        </>
    );
}


export default DatePickerComponent;
