import httpClient from "../../utility/httpClient"
import { FiltersInterface } from "../../utility/filtersUtils";
import { PaginationFiltersInterface } from "../CommonInterfaces";
import { SoldFilters } from "./SoldFilterInterfaces";
import { InventoryListInterface } from "../InventoryInterfaces";

export default class SoldListService {

    MAX_ELEMENTS: number = 15;

    searchUrl = "/api/v1/inventory/";

    public async getProducts(sortFilters?: FiltersInterface[], filters?: SoldFilters, paginationFilter?: PaginationFiltersInterface): Promise<InventoryListInterface> {

        let orderBy = (sortFilters?.find(e => e.lastSelect)!.value ? "+" : "-") + (sortFilters?.find(e => e.lastSelect)!.name);

        let id = 'USERIDAUTH0'; //:invIdId
        let path = "/filterPurchases";
        let page = "?page=" + (paginationFilter?.page ? paginationFilter?.page + 1 : "1") + "&";
        let sizePage = "itemperpage=" + this.MAX_ELEMENTS;
        let body = {
            "Name": filters?.searchFilters || "",
            "PurchaseDate": filters?.purDateFilter || [],
            "SaleDate": filters?.saleDateFilter || [],
            "ShippingDate": [],
            "Slug": "sold",
            "Sizes": filters?.sizeFilter || [],
            "PurchaseStore": filters?.purStoreFilter || [],
            "SaleStore": filters?.saleStoreFilter || [],
            "ListedOn": [],
            "OrderBy": orderBy,
            "With": "purchase.item,purchase.variant,variant.size,purchase.listings,purchase.sale"
        };
        let url = this.searchUrl + id + path + page + sizePage;
        return httpClient.post(url, body).then((e: any) => {
            return e.data
        });
    }

    public async deleteSale(itemsIds: Array<string>): Promise<any> {
        let id = 'USERIDAUTH0'; //:invIdId
        let url = this.searchUrl + id + '/sales';
        let body = {
            'itemsIds': itemsIds
        }
        return httpClient.delete(url, { data: body }).then((e: any) => e.data);
    }

    public async updateSale(itemId: string, item: Record<any, any>): Promise<any> {
        let id = 'USERIDAUTH0'; //:invIdId
        let itemPath = ('/' + itemId);
        let url = this.searchUrl + id + itemPath;
        return httpClient.patch(url, item).then((e: any) => e.data);
    }

}