import React, { useEffect, useState } from 'react';
import './PurchaseSection.scss';
import { InventoryResultInterface } from "../../../../../services/InventoryInterfaces";
import Input from "../../../../common/input/Input";
import { formatPrice, getDateByTimeStamp } from "../../../../../utility/commonUtils";
import UserDataStorage from "../../../../../singletonStorage/userDataStorage";
import Select from '../../../../common/select/Select';
import { schemaErrorsViewOrEditModal } from "../../ViewOrEditItemModal";
const _ = require('lodash');

interface ListingSectionProps {
    item?: InventoryResultInterface,
    locked: boolean,
    onChange: (args: Record<any, any>) => void;
    error?: typeof schemaErrorsViewOrEditModal["purchase"];
}

const PurchaseSection: React.FC<ListingSectionProps> = (props) => {

    const userData = UserDataStorage.getInstance();

    const [item, setItem] = useState<InventoryResultInterface | undefined>(props.item);

    const updateItem = (fieldToUpdate: Partial<InventoryResultInterface>) => {
        setItem((item: any) => {
            if (item) {
                return { ..._.merge(item, fieldToUpdate) };
            } else {
                return { ..._.merge(fieldToUpdate) };
            }
        })
        if (item) {
            props.onChange({
                ...fieldToUpdate
            })
        }

    }

    return (
        <>
            <div>
                {props.item && <><div className={"mt-3 pt-3 row g-0"}>
                    <div className={"col-6 col-sm-4 pe-2"}>
                        <label className={"labelModal"} htmlFor={"purDate"}>Purchase Date</label>
                        <Input id={"purDate"}
                            type="date"
                            placeholder={"DD-MM-YYYY"}
                            defaultValue={getDateByTimeStamp(props.item.purchaseDate, true)}
                            onChange={(str) => updateItem({ purchaseDate: str })}
                            disabled={props.locked}
                            error={props.error?.purchaseDate}
                        />
                    </div>
                    <div className={"col-6 col-sm-4 pe-2"}>
                        <label className={"labelModal"} htmlFor={"purDate"}>Purchase Store</label>
                        <Select id={"purStore"}
                            key={"purStoreSelect" + item?.retailerId}
                            customClass={"smallPlaceholder w-100"}
                            selectedValue={item?.retailerId}
                            value={userData.sessionStorage!['retailers']}
                            onChange={(str) => updateItem({ ...{ retailerId: str } })}
                            disabled={props.locked}
                        />
                    </div>
                    <div className={"col-6 col-sm-4"}>
                        <label className={"labelModal"} htmlFor={"purDate"}>Purchase Price</label>
                        <Input id={"purPrice"}
                            type={"price"}
                            onChangeSelect={(str) =>
                                updateItem({
                                    price: {
                                        currency: str,
                                        value: item!.price.value
                                    }
                                })}
                            valueSelect={userData.sessionStorage!['currencies']}
                            defaultValue={formatPrice(item?.price?.value)}
                            defaultValueSelect={item?.price?.currency}
                            onChange={(str) => updateItem({
                                price: {
                                    currency: item!.price.currency,
                                    value: Number(Number(str?.replaceAll(',', '.')).toFixed(2))
                                }
                            })}
                            disabled={props.locked}
                        />
                    </div>
                </div>
                    <div className={"mt-3 pt-3"}>
                        <div>
                            <label htmlFor="notes" className="labelModal mb-0">Notes</label>
                        </div>
                        <textarea disabled={props.locked} className="text-area-settings w-100" id="notes" value={item!.notes} onChange={(event) => updateItem({ notes: event.target.value })}></textarea>
                    </div>
                </>}
                {!props.item && <>
                    <div className={"mt-4 pt-4 mb-4 pb-4 color-white text-center"}>
                        <div className={"mt-4 pt-4 mb-2 pb-2"}><i className="fa-solid fa-face-dizzy h1"></i></div>
                        <div>Uh Oh! No Purchase Found for this item</div>
                    </div>
                </>
                }
            </div>
        </>
    );
}


export default PurchaseSection;